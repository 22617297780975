import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
  .css-ivjue7-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
    
      color: white;
  }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .input {
    margin: 7px 0;
  }
  label {
    color: #666;
  }
  .title {
    margin-bottom: 0;
  }
  .subTitle {
    font-style: italic;
    font-size: 15px;
    margin-top: 0;
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  .button {
    margin: 30px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .small {
    width: 70%;
  }
  .smaller {
    width: 27%;
  }
`
export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h4{
    color: #000;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .top {
    margin: 15px auto;
    width: 100%;
  }
  
`
export const LoadingContainer = styled.div`
  align-self: center;
  margin: 130px auto;
`
export const InformationContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.palette.primary.main};
  padding: 10px
`
