import styled from 'styled-components'

const xs = 400
const sm = 600
const md = 800
const xl = 1260
const xxl = 1400

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  box-sizing: border-box;
  color: #ccc;
  margin: 0;
`
export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin: 30px auto;
  //aspect-ratio: 2.45/1;
  .orcaButton {
    background-color: #F1B617;
    color: #000;
    font-weight: bold;
    border-color: #F1B617;
    box-shadow: 1px 1px 3px #555;
    padding: 10px;
    width: 45%;
    margin: 10px auto;
    // box-shadow: 1px 1px 2px #000;
    &:hover {
        background-color: #F1B617CC;
        border-color: #F1B617;
      }    
  }
  .input {
    width: 45%;
    outline: none;
    margin: 10px auto;
    &:hover {
      border-color: #F1B617;
    } 
    label {
      color: #444;
    }
  }
  h2 {
    color: #F1B617;
  }
`

export const LogoContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
`
export const FormContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  padding: 10px;
  p {
    color: #fff;
  }
  @media (max-width: ${sm}px) {
    width: 100%;
    margin-top: 0;
  }
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    margin-top: -10px;
  }
  @media (min-width: ${md}px) and (max-width: ${xl}px) {
    margin-top: 0;
  }
  @media (min-width: ${xxl}px) {
    width: 40%;
  }
`
export const TextContainer = styled.div`
  width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  p {
    font-size: 18px;
    color: #000;
  }
  h2 {
    color: #F1B617;
  }
  button {
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
      width: 70%;
      font-size: 10px;
      margin: 10px auto;
    }
    @media (min-width: ${md}px) and (max-width: ${xl}px) {
      width: 70%;
      margin: 12px auto;
    }
    @media (max-width: ${sm}px) {
      width: 75%;
      margin: 10px auto;
    }
    @media (max-width: ${xs}px) {
      width: 85%;
      margin: 10px auto;
    }
  }
`

export const ProfileImg = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 60px;
`
export const LogoImg = styled.img`
  width: 25%;
  @media (max-width: ${sm}px) {
    width: 45%;
  }
`

export const LinkContainer = styled.div`
  width: 45%;
  //cursor: pointer;
  background-color: #F1B617;
  border-radius: 4px;
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
      width: 60%;
      font-size: 10px;
      margin: 10px auto;
    }
    @media (min-width: ${md}px) and (max-width: ${xl}px) {
      width: 40%;
      margin: 12px auto;
    }
    @media (max-width: ${sm}px) {
      width: 60%;
      margin: 10px auto;
    }
    @media (max-width: ${xs}px) {
      width: 85%;
      margin: 10px auto;
    }
  a {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
    padding: 10px;
    &:active, &focus {
      color: #000;
      font-weight: bold;
      text-decoration: none;
    }
  }
    border-color: #F1B617;
    box-shadow: 1px 1px 3px #555;
    padding: 10px;
    margin: 10px auto;
    &:hover {
        background-color: #F1B617CC;
        border-color: #F1B617;
      }    
`
