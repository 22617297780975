import styled from 'styled-components'

const sm = 600

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  box-sizing: border-box;
  color: #ccc;
  margin: 0;
`
export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin: 30px auto;
  .orcaButton {
    background-color: #F1B617;
    color: #000;
    font-weight: bold;
    border-color: #F1B617;
    box-shadow: 1px 1px 3px #555;
    padding: 10px;
    width: 45%;
    margin: 10px auto;
    &:hover {
        background-color: #F1B617CC;
        border-color: #F1B617;
      }    
  }
  h2 {
    color: #F1B617;
  }
`

export const LogoContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
`

export const TextContainer = styled.div`
  width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  p {
    font-size: 18px;
    color: #000;
  }
  h2 {
    color: #F1B617;
  }
`

export const LogoImg = styled.img`
  width: 25%;
  @media (max-width: ${sm}px) {
    width: 45%;
  }
`
