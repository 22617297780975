import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined'
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined'
import SportsBarOutlinedIcon from '@mui/icons-material/SportsBarOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined'
import LiquorOutlinedIcon from '@mui/icons-material/LiquorOutlined'
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { Link } from 'react-router-dom'
import { theme } from '../../config/theme'

interface Props {
  handleDrawerClose?: () => void
}

export const ListLinks = ({ handleDrawerClose }: Props): JSX.Element => {

  return (
    <List>
      {
        [
          {
            label: 'Dashboard',
            icon: InsertChartOutlinedIcon,
            route: '/'
          },
          {
            label: 'Fornecedores',
            icon: PermContactCalendarOutlinedIcon,
            route: '/fornecedores'
          },
          {
            label: 'Lojistas',
            icon: PersonOutlineOutlinedIcon,
            route: '/lojistas'
          },
          {
            label: 'Orçamentos',
            icon: PriceChangeOutlinedIcon,
            route: '/orcamentos'
          },
          {
            label: 'Compras Coletivas',
            icon: AddShoppingCartIcon,
            route: '/compras-coletivas'
          },
          {
            label: 'Indicação',
            icon: WorkspacePremiumOutlinedIcon,
            route: '/indicacoes'
          },
          {
            label: 'Categorias',
            icon: CollectionsBookmarkOutlinedIcon,
            route: '/categorias'
          },
          {
            label: 'Unidades',
            icon: LiquorOutlinedIcon,
            route: '/unidades'
          },
          {
            label: 'Produtos',
            icon: SportsBarOutlinedIcon,
            route: '/produtos'
          },
          {
            label: 'Anúncios',
            icon: CampaignOutlinedIcon,
            route: '/anuncios'
          },
          {
            label: 'Exclusão de conta',
            icon: PersonOffOutlinedIcon,
            route: '/exclusao-de-conta'
          },
          {
            label: 'Financeiro',
            icon: MonetizationOnOutlinedIcon,
            route: 'https://dashboard.stripe.com/dashboard'
          }
        ].map((info, index) => (
          <React.Fragment key={index}>
            <Link onClick={handleDrawerClose} style={{ color: 'inherit', textDecoration: 'none' }} to={info.route}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <info.icon color={location.pathname === info.route ? 'primary' : 'inherit'} />

                  </ListItemIcon>
                  <ListItemText primary={info.label} sx={{ color: location.pathname === info.route ? theme.palette.primary.main : 'inherit' }} />
                </ListItemButton>
              </ListItem>
            </Link>
          </React.Fragment>
        ))}
    </List>
  )
}
