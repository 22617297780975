// Arquivo criado: 26/08/2023 às 15:46
import React from 'react'
import * as S from './styles'
import orcaLogo from '../../assets/logo_horizontal_fundo_branco.png'
import basicProfile from '../../assets/Profile-PNG-File.png'
import { backEnd } from '../../utils/backend.util'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { setOpenBackDropLoading } from '../../redux/actions/backDropLoading.action'

interface IInviteDataResponse {
  nome: string
  imgPerfil: string | null
}

export const Invite = (): JSX.Element => {

  const dispatch = useDispatch()
  const { search } = useLocation()

  const [link, setLink] = React.useState<string | null>(null)

  const [user, setUser] = React.useState<IInviteDataResponse | null>(null)

  React.useEffect(() => {
    document.title = 'Carregando...'
    const getData = async (): Promise<void> => {

      const params = new URLSearchParams(search)
      const inviteCode = params.get('code')

      if (!inviteCode) {
        document.title = 'Convite inválido'
        return
      }

      dispatch(setOpenBackDropLoading(true))
      const response = await backEnd('GET', `usuario/indicador/${inviteCode}`)
      dispatch(setOpenBackDropLoading(false))

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          type: 'error'
        }))

        document.title = 'Erro'
        return
      }

      const data: IInviteDataResponse = response.data

      document.title = `Convite de ${data.nome}`
      setUser(data)
      setLink(`orcabeer://--/Preencha%20seus%20dados?indicacaoCode=${inviteCode}`)
    }

    void getData()
  }, [dispatch, search])

  return (
    <S.Container>
      <S.ScreenContainer>
        <S.LogoContainer>
          <S.LogoImg src={orcaLogo} />
        </S.LogoContainer>
        <S.ProfileImg src={user?.imgPerfil !== null ? user?.imgPerfil : basicProfile} />
        {
          user && (
            <S.TextContainer>
              <h2>{user.nome} enviou um convite para você!</h2>
              <p>Você está sendo indicado por <strong>{user.nome}</strong> para entrar no OrçaBeer.<br />
                Clique no botão abaixo para se cadastrar e fazer orçamentos com facilidade e segurança!</p>
            </S.TextContainer>
          )
        }
        <S.FormContainer>
          <S.TextContainer>
            {
              link && (
                <S.LinkContainer>
                  <a href={link} target='_blank' rel="noreferrer">CADASTRE-SE</a>
                </S.LinkContainer>
              )
            }
          </S.TextContainer>
        </S.FormContainer>
      </S.ScreenContainer>
    </S.Container>
  )
}
