// Arquivo criado: 22/09/2023 às 18:33
import React from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import CircularProgress from '@mui/material/CircularProgress'

interface Data {
  categoria: string
  id: number
  criadoEm: Date
  icons: string
}

export const Categorias = (): JSX.Element => {

  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  const [componentId, setComponentId] = React.useState<number | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [sortedData, setSortedData] = React.useState<Data[]>([])
  const [isSorted, setIsSorted] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [editableItem, setEditableItem] = React.useState<Data | null>(null)
  const [novaCategoria, setNovaCategoria] = React.useState('')
  const [willDelete, setWillDelete] = React.useState(false)
  const [get, setGet] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Categorias'

      setLoading(true)
      const response = await backEnd('GET', 'categorias/produtos')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setComponent(response.data)

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [dispatch, get])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },

    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  interface HeadCell {
    id: keyof Data
    title: string
  }

  const titles: readonly HeadCell[] = [
    {
      title: 'Categoria',
      id: 'categoria'
    },
    {
      title: 'Cadastro',
      id: 'criadoEm'
    },
    {
      title: '',
      id: 'icons'
    }
  ]

  const handleSort = (key: keyof Data): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      if (a[`${key}`] < b[`${key}`]) return -1
      if (a[`${key}`] > b[`${key}`]) return 1
      return 0
    })
    setSortedData(sortedArray)
    setIsSorted(true)
  }

  const handleRevert = (): void => {
    const revertedArray = [...component]
    revertedArray.reverse()
    setSortedData(revertedArray)
    setIsSorted(false)
  }

  const handleCloseEdit = (): void => {
    setNovaCategoria('')
    setComponentId(null)
    setEditableItem(null)
    setOpenEdit(false)
  }

  const handleCloseDelete = (): void => {
    setNovaCategoria('')
    setComponentId(null)
    setEditableItem(null)
    setWillDelete(false)
    setOpenDelete(false)
  }

  const handleCloseCreate = (): void => {
    setNovaCategoria('')
    setOpenCreate(false)
  }

  const handleOpenCreate = (): void => {
    setOpenCreate(true)
  }

  const handleCategoria = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNovaCategoria(event.target.value)
  }

  const handleSave = async (): Promise<void> => {
    if (!novaCategoria) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a categoria para continuar',
        type: 'error'
      }))
      return
    }

    if (novaCategoria.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A categoria deve conter no máximo 255 caracteres',
        type: 'error'
      }))
      return
    }

    const body = {
      categoria: {
        categoria: novaCategoria
      }
    }
    setLoading(true)
    const response = await backEnd('POST', 'categoria', body)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setNovaCategoria('')
    setOpenCreate(false)
    setGet(true)
  }

  const handleDelete = (component: Data): void => {
    setEditableItem(component)
    setComponentId(component.id)
    setOpenDelete(true)
  }

  const handleSaveDelete = async (): Promise<void> => {

    if (componentId === null) return

    const response = await backEnd('DELETE', `categoria/${componentId}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    const response2 = await backEnd('GET', 'categorias/produtos')

    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response2.msg
      }))
      return
    }
    setComponent(response2.data)

    setNovaCategoria('')
    setOpenDelete(false)
    setComponentId(null)
    setEditableItem(null)
    setWillDelete(false)
  }

  const handleEdit = (component: Data): void => {
    setEditableItem(component)
    setComponentId(component.id)
    setOpenEdit(true)
  }

  const handleProdutoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNovaCategoria(event.target.value)
  }

  const handleSaveChanges = async (): Promise<void> => {
    if (!novaCategoria) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a categoria para continuar',
        type: 'error'
      }))
      return
    }

    if (novaCategoria.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A categoria deve conter no máximo 255 caracteres',
        type: 'error'
      }))
      return
    }

    const body = {
      categoria: novaCategoria
    }

    if (componentId === null) return

    const response = await backEnd('PUT', `categoria/${componentId}`, body)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    const response2 = await backEnd('GET', 'categorias/produtos')

    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response2.msg
      }))
      return
    }
    setComponent(response2.data)

    setNovaCategoria('')
    setComponentId(null)
    setEditableItem(null)
    setOpenEdit(false)
  }

  return (
    <S.Container>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openCreate}
        onClose={handleCloseCreate}
      >
        <DialogContent>
          <>
            <InputLabel sx={{ color: '#000', mb: 4 }}>Informe o nome da categoria:</InputLabel>
            <S.RowContainer>
              <S.ColumnContainer width={95}>
                <TextField fullWidth className='input' size='small' id='novaCategoria' name='novaCategoria' defaultValue={novaCategoria} onChange={handleCategoria} label='Nova Categoria' />
              </S.ColumnContainer>
            </S.RowContainer>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Salvar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseCreate}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openEdit}
        onClose={handleCloseEdit}
      >
        <DialogContent>
          <S.ColumnContainer width={90}>
            <h3 className='title'>{editableItem !== null ? editableItem.categoria : 'Categoria'}</h3>
          </S.ColumnContainer>
          {
            editableItem !== null && (
              <>
                <InputLabel sx={{ color: '#000', mb: 4 }}>Editar informações:</InputLabel>
                <S.RowContainer>
                  <S.ColumnContainer width={100}>
                    <TextField fullWidth className='input' size='small' id='novoNome' name='novoNome' defaultValue={editableItem.categoria} onChange={handleProdutoChange} label='Nome editado' />
                  </S.ColumnContainer>
                </S.RowContainer>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveChanges}>Salvar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEdit}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openDelete}
        onClose={handleCloseDelete}
      >
        <DialogContent>
          <S.ColumnContainer width={90}>
            <h3 className='title'>{editableItem !== null ? editableItem.categoria : 'Categoria'}</h3>
          </S.ColumnContainer>
          {
            editableItem !== null && (
              <>
                <InputLabel sx={{ color: '#000', mb: 4 }}>Deletar categoria?</InputLabel>
                <S.ColumnContainer width={90}>
                  <p>Atenção: Essa ação não poderá ser desfeita. Deseja prosseguir com a exclusão da categoria?</p>
                  <S.RowContainer>
                    <Button onClick={() => { setWillDelete(true) }}>Sim, quero deletar</Button>
                    <Button onClick={handleCloseDelete}>Não, manter esta categoria</Button>
                  </S.RowContainer>
                </S.ColumnContainer>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={!willDelete} onClick={handleSaveDelete}>Deletar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDelete}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      {
        loading
          ? (
            <S.LoadingContainer>
              <CircularProgress />
            </S.LoadingContainer>
          )
          : (
            <S.ScreenContainer>
              <S.RowContainer className='top'>
                {
                  component.length > 0
                    ? (
                      <h4 style={{ color: '#000' }}>Fo{component.length !== 1 ? 'ram' : 'i'} encontrada{component.length !== 1 ? 's' : ''} {component.length} categoria{component.length !== 1 ? 's' : ''}:</h4>
                    )
                    : (
                      <h4>Não foi possível encontrar categorias cadastradas</h4>
                    )
                }
                <Button className='produtos' variant='outlined' onClick={handleOpenCreate}>Cadastrar nova categoria</Button>
              </S.RowContainer>
              {
                component.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} stickyHeader>
                      <TableHead>
                        <TableRow>
                          {
                            titles.map((item, index) => (
                              <StyledTableCell key={index}><Button onClick={() => { !isSorted ? handleSort(item.id) : handleRevert() }}>{item.title}</Button></StyledTableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          sortedData.length > 1
                            ? (
                              <>
                                {
                                  sortedData.map((item, index) => {
                                    return (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{item.categoria}</StyledTableCell>
                                        <StyledTableCell align="left">{dayjs(item.criadoEm).format('DD/MM/YYYY')}</StyledTableCell>
                                        <StyledTableCell align="left">
                                          <S.RowContainer>
                                            <Button onClick={() => { handleDelete(item) }}><DeleteOutlinedIcon /></Button>
                                            <Button onClick={() => { handleEdit(item) }}><ModeOutlinedIcon /></Button>
                                          </S.RowContainer>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )
                                  })
                                }
                              </>
                            )
                            : (
                              <>
                                {
                                  component.map((item, index) => {
                                    return (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{item.categoria}</StyledTableCell>
                                        <StyledTableCell align="left">{dayjs(item.criadoEm).format('DD/MM/YYYY')}</StyledTableCell>
                                        <StyledTableCell align="left">
                                          <S.RowContainer>
                                            <Button onClick={() => { handleDelete(item) }}><DeleteOutlinedIcon /></Button>
                                            <Button onClick={() => { handleEdit(item) }}><ModeOutlinedIcon /></Button>
                                          </S.RowContainer>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )
                                  })
                                }
                              </>
                            )
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
              }
            </S.ScreenContainer>
          )
      }
    </S.Container>
  )
}
