// Arquivo criado: 03/04/2024 às 12:41
import React from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import dayjs from 'dayjs'

// Pagination ------------------------------------------------------------------------
interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps): JSX.Element => {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}
// -----------------------------------------------------------------------------------------

interface IStatus {
  id: number
  status: string
}

interface IPedido {
  prazoEntrega: Date
  id: number
}

interface ILojista {
  nomeFantasia: string
}

interface Proposta {
  id: number
  subId: number
  prazoEntrega: Date
  status: IStatus
  pedido: IPedido
  lojista: ILojista
}

interface Resumo {
  data: Date
  fornecedor: string | null
  ordem: number
  qtdProdutos: number
  type: string
}

export const Orcamentos = (): JSX.Element => {

  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Proposta[]>([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Orçamentos'

      setLoading(true)
      const response = await backEnd('GET', 'propostas')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }

      setComponent(response.data)

    }
    void getData()
  }, [dispatch])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableCellDetail = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 'none',
      padding: 0
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },

    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  // Pagination -----------------------------------------------------------

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - component.length) : 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // ---------------------------------------------------------------------------

  const [open, setOpen] = React.useState(false)
  const [componentId, setComponentId] = React.useState<number | null>(null)
  const [resumo, setResumo] = React.useState<Resumo[]>([])
  const [loadingResumo, setLoadingResumo] = React.useState(false)
  const [componentIndex, setComponentIndex] = React.useState<number | null>(null)
  const [isSorted, setIsSorted] = React.useState(false)

  const openDetails = async (component: Proposta, index: number): Promise<void> => {
    if (open && componentIndex !== index) {
      setOpen(false)
    }
    setComponentId(component.id)
    if (component === null) return

    setLoadingResumo(true)
    const response = await backEnd('GET', `proposta/${component.id}/resumo`)
    setLoadingResumo(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setResumo(response.data)
    setComponentIndex(index)
    setOpen(true)
  }

  const closeDetails = (component: Proposta, index: number): void => {
    setComponentId(null)
    setOpen(false)
    setResumo([])
    if (index !== componentIndex) {
      void openDetails(component, index)
    }
  }

  const sortByLojista = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.lojista.nomeFantasia.localeCompare(b.lojista.nomeFantasia, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByLojista = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.lojista.nomeFantasia.localeCompare(b.lojista.nomeFantasia, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByID = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      const aIndex = parseFloat(`${a.pedido.id}${a.subId}`)
      const bIndex = parseFloat(`${b.pedido.id}${b.subId}`)

      if (aIndex < bIndex) return -1
      if (aIndex > bIndex) return 1
      return 0
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByID = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      const aIndex = parseFloat(`${a.pedido.id}${a.subId}`)
      const bIndex = parseFloat(`${b.pedido.id}${b.subId}`)

      if (aIndex < bIndex) return -1
      if (aIndex > bIndex) return 1
      return 0
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByDate = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      if (a.prazoEntrega < b.prazoEntrega) return -1
      if (a.prazoEntrega > b.prazoEntrega) return 1
      return 0
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByDate = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      if (a.prazoEntrega < b.prazoEntrega) return -1
      if (a.prazoEntrega > b.prazoEntrega) return 1
      return 0
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByLimitDate = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      if (a.pedido.prazoEntrega < b.pedido.prazoEntrega) return -1
      if (a.pedido.prazoEntrega > b.pedido.prazoEntrega) return 1
      return 0
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByLimitDate = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      if (a.pedido.prazoEntrega < b.pedido.prazoEntrega) return -1
      if (a.pedido.prazoEntrega > b.pedido.prazoEntrega) return 1
      return 0
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByStatus = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      if (a.status.status < b.status.status) return -1
      if (a.status.status > b.status.status) return 1
      return 0
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByStatus = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      if (a.status.status < b.status.status) return -1
      if (a.status.status > b.status.status) return 1
      return 0
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const defaultLabelDisplayedRows = ({ from, to, count }: {
    from: number
    to: number
    count: number
  }): string => (`${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`)

  return (
    <S.Container>
      {
        loading
          ? (
            <S.LoadingContainer>
              <CircularProgress />
            </S.LoadingContainer>
          )
          : (
            <S.ScreenContainer>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        <Button onClick={() => {
                          !isSorted
                            ? sortByLojista()
                            : revertArrayByLojista()
                        }}>Lojista
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button onClick={() => {
                          !isSorted
                            ? sortByID()
                            : revertArrayByID()
                        }}>Número
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button onClick={() => {
                          !isSorted
                            ? sortByDate()
                            : revertArrayByDate()
                        }}>Entrega Prevista
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button onClick={() => {
                          !isSorted
                            ? sortByStatus()
                            : revertArrayByStatus()
                        }}>Status
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button onClick={() => {
                          !isSorted
                            ? sortByLimitDate()
                            : revertArrayByLimitDate()
                        }}>Prazo Limite
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? component.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : component
                    ).map((item, index) => (
                      <React.Fragment key={index}>
                        <StyledTableRow>
                          <StyledTableCell>
                            {item.lojista.nomeFantasia}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.pedido.id}.{item.subId}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {dayjs(item.prazoEntrega).format('DD/MM/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.status.status}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {dayjs(item.pedido.prazoEntrega).format('DD/MM/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              aria-label="expand row"
                              size="large"
                              onClick={async () => { open ? closeDetails(item, index) : await openDetails(item, index) }}
                            >
                              {componentId === item.id && open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                        <TableRow>
                          <StyledTableCellDetail colSpan={6}>
                            <Collapse className='collapse' in={componentId === item.id} timeout="auto">
                              {
                                loadingResumo
                                  ? (
                                    <S.LoadingResumoContainer>
                                      <CircularProgress />
                                    </S.LoadingResumoContainer>
                                  )
                                  : (
                                    <div className='details-container'>
                                      {
                                        resumo.length > 0 && (
                                          resumo.map((item: Resumo) => (
                                            <div className={item.ordem !== resumo.length ? 'detail-box detail-box-border' : 'detail-box'} key={item.ordem}>
                                              <S.ColumnContainer style={{ width: '100%' }}>
                                                <p className='info'>Em <b>{dayjs(item.data).format('DD/MM/YYYY')}</b> às <b>{dayjs(item.data).format('hh:mm')}</b> - o {item.fornecedor ? `fornecedor ${item.fornecedor}` : 'lojista'} {item.type === 'valores_enviados' ? 'informou os preços dos produtos.' : item.type === 'alteracao_item' ? 'fez alterações no orçamento.' : 'aceitou a negociação.'}</p>
                                                <p className='qtd'>{item.qtdProdutos} produto{item.qtdProdutos !== 1 ? 's' : ''} em negociação</p>

                                              </S.ColumnContainer>
                                            </div>

                                          ))
                                        )
                                      }
                                    </div>
                                  )
                              }
                            </Collapse>
                          </StyledTableCellDetail>
                        </TableRow>
                      </React.Fragment>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, { label: 'Todos', value: -1 }]}
                        colSpan={6}
                        count={component.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'Orçamentos por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </S.ScreenContainer>
          )
      }
    </S.Container>
  )
}
