import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
`
export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  @media (max-width: ${md}px) {
    flex-direction: row;
    align-items: center;
    }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .input {
    margin: 7px 0;
  }
  label {
    color: #666;
  }
  .title {
    margin-bottom: 0;
  }
  .subTitle {
    font-style: italic;
    font-size: 15px;
    margin-top: 0;
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h4{
    color: #000;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .top {
    margin: 15px auto;
    width: 100%;
  }
  .endereco {
    cursor: initial;
  }

  .endereco:hover, .endereco:focus, .endereco:active {
    background-color: #000;
  }
  
`
export const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`

export const DialogContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  label {
    color:  ${theme.palette.primary.main};
  }
`

export const DialogEndereco = styled.div`
  width: 90%;
  margin: 2px auto;
  display: flex;
  flex-direction: column;
  label {
    color:  ${theme.palette.primary.main};
  }
  h4 {
    margin-bottom: 2px;
  }
  p {
    font-size: 13px;
    margin-top: 0;
  }
`
