// Arquivo criado: 21/09/2023 às 19:54
import React from 'react'
import * as S from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from 'dayjs'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { theme } from '../../config/theme'
import { type IEndereco } from '../../interfaces/IEndereco'
import { DialogArchiveUser } from '../../components/DialogArchiveUser'

interface Endereco {
  bairro?: string | null
  cep: string | null
  cidade: string | null
  complemento?: string | null
  estado: string | null
  numero: number | null
  rua: string | null
}

interface Data {
  nomeFantasia: string
  cpfResponsavel: string
  criadoEm: Date
  statusId: number
  nomeResponsavel: string
  email: string
  telefone: string
  id: number
  Endereco: IEndereco[]
}

interface IStatus {
  id: number
  status: string
}

export const Lojistas = (): JSX.Element => {

  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  const [loading, setLoading] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<Data | null>(null)
  const [componentId, setComponentId] = React.useState<number | null>(null)
  const [status, setStatus] = React.useState<string>('Status')
  const [allStatus, setAllStatus] = React.useState<IStatus[]>([])
  const [open, setOpen] = React.useState(false)
  const [isSorted, setIsSorted] = React.useState(false)
  const [openEndereco, setOpenEndereco] = React.useState(false)
  const [allEnderecos, setAllEnderecos] = React.useState<Endereco[]>([])
  const [archiveUser, setArchiveUser] = React.useState<number | null>(null)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Lojistas'

      setLoading(true)
      const response = await backEnd('GET', 'usuarios/2')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      setComponent(response.data)
    }
    void getData()
  }, [userId, dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      const response = await backEnd('GET', 'usuario/status')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setAllStatus(response.data)
    }
    void getData()
  }, [dispatch])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleClickOpen = (component: Data): void => {
    setSelectedItem(component)
    setComponentId(component.id)
    setStatus((component.statusId).toString())
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleOpenEndereco = async (component: Data): Promise<void> => {
    setSelectedItem(component)

    const response = await backEnd('GET', `endereco/${component.id}`)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setAllEnderecos(response.data)
    setOpenEndereco(true)
  }

  const handleCloseEndereco = (): void => {
    setOpenEndereco(false)
    setSelectedItem(null)
    setAllEnderecos([])
  }

  const handleChange = (event: SelectChangeEvent): void => {
    setStatus(event.target.value)
  }

  const handleSave = async (): Promise<void> => {
    handleClose()

    if (!componentId) return
    if (!status) return

    const response = await backEnd('PUT', `usuario/${componentId}/alterar-status/${status}`)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    const response2 = await backEnd('GET', 'usuarios/2')
    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }
    setComponent(response2.data)

  }

  const phoneMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
  }

  const cpfMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d)(\d{2})$/, '$1-$2')
    return value
  }

  const handleSort = (key: keyof Data): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      if (a[`${key}`] < b[`${key}`]) return -1
      if (a[`${key}`] > b[`${key}`]) return 1
      return 0
    })
    setComponent(sortedArray)
  }

  const revertArrayByGeneric = (key: keyof Data): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      if (a[`${key}`] < b[`${key}`]) return -1
      if (a[`${key}`] > b[`${key}`]) return 1
      return 0
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByNomeFantasia = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.nomeFantasia.localeCompare(b.nomeFantasia, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByNomeFantasia = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.nomeFantasia.localeCompare(b.nomeFantasia, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByNomeResponsavel = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.nomeResponsavel.localeCompare(b.nomeResponsavel, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByNomeResponsavel = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.nomeResponsavel.localeCompare(b.nomeResponsavel, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByEmail = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.email.localeCompare(b.email, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByEmail = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.email.localeCompare(b.email, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const handleArchive = (id: number): void => {
    setArchiveUser(id)
  }

  return (
    <S.Container>

      <DialogArchiveUser
        userId={archiveUser}
        setUserId={setArchiveUser}
      />

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {selectedItem !== null ? selectedItem.nomeFantasia : 'Fornecedor'}
        </DialogTitle>
        <DialogContent>
          <div>
            <InputLabel sx={{ color: '#000' }}>Selecione o novo status</InputLabel>
            <Select
              value={status}
              label="Selecione o novo status"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={8}>Aprovado</MenuItem>
              <MenuItem value={2}>Em análise</MenuItem>
              <MenuItem value={3}>Reprovado</MenuItem>
              <MenuItem value={4}>Banido</MenuItem>
              <MenuItem value={5}>Bloqueado</MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Salvar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openEndereco}
        onClose={handleCloseEndereco}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {selectedItem !== null ? selectedItem.nomeFantasia : 'Lojista'}
        </DialogTitle>
        <S.DialogContent>
          <S.DialogEndereco>
            <h3>{allEnderecos.length !== 1 ? 'Endereços cadastrados:' : 'Endereço cadastrado:'}</h3>
            {
              allEnderecos.map((item: Endereco, index) => {
                return (
                  <div key={index}>
                    <div>
                      <h4>{item.rua}{item.numero !== null ? `, n° ${item.numero},` : ','} {item.cidade} - {item.estado}</h4>
                      <p>CEP: {item.cep !== null ? item.cep.replace(/(\d)(\d{3})$/, '$1-$2') : 'não informado'}</p>
                    </div>
                  </div>
                )
              })

            }
          </S.DialogEndereco>
        </S.DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEndereco}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                {
                  component.length > 0
                    ? (
                      <>
                        <S.RowContainer className='top'>
                          <h4 style={{ color: '#000' }}>Fo{component.length !== 1 ? 'ram' : 'i'} encontrado{component.length !== 1 ? 's' : ''} {component.length} lojista{component.length !== 1 ? 's' : ''}:</h4>
                        </S.RowContainer>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 700 }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? sortByNomeFantasia()
                                    : revertArrayByNomeFantasia()
                                }}>Nome</Button></StyledTableCell>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? handleSort('cpfResponsavel')
                                    : revertArrayByGeneric('cpfResponsavel')
                                }}>CPF</Button></StyledTableCell>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? handleSort('criadoEm')
                                    : revertArrayByGeneric('criadoEm')
                                }}>Cadastro</Button></StyledTableCell>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? handleSort('statusId')
                                    : revertArrayByGeneric('statusId')
                                }}>Status</Button></StyledTableCell>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? sortByNomeResponsavel()
                                    : revertArrayByNomeResponsavel()
                                }}>Responsável</Button></StyledTableCell>
                                <StyledTableCell><Button className='endereco'>Endereço</Button></StyledTableCell>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? sortByEmail()
                                    : revertArrayByEmail()
                                }}>Email</Button></StyledTableCell>
                                <StyledTableCell><Button onClick={() => {
                                  !isSorted
                                    ? handleSort('telefone')
                                    : revertArrayByGeneric('telefone')
                                }}>Telefone</Button></StyledTableCell>

                                <StyledTableCell><Button>-</Button></StyledTableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                component.map((item, index) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">{item.nomeFantasia}</StyledTableCell>
                                      <StyledTableCell align="left">{cpfMask(item.cpfResponsavel)}</StyledTableCell>
                                      <StyledTableCell align="left">{dayjs(item.criadoEm).format('DD/MM/YYYY')}</StyledTableCell>
                                      <StyledTableCell align="left"><Button style={item.statusId === 1 || item.statusId === 2 ? { color: theme.palette.primary.main } : item.statusId === 8 ? { color: 'green' } : { color: 'red' }} onClick={() => { handleClickOpen(item) }}>{allStatus.map((elem) => (elem.id === item.statusId ? `${elem.status}` : null))}</Button></StyledTableCell>
                                      <StyledTableCell align="left">{item.nomeResponsavel}</StyledTableCell>
                                      <StyledTableCell align="left">{item.Endereco[0].rua}{item.Endereco[0].numero !== null ? `, n° ${item.Endereco[0].numero},` : ','} {item.Endereco[0].bairro}, {item.Endereco[0].cidade} - {item.Endereco[0].estado}<Button style={{ color: theme.palette.primary.main, display: 'block', textTransform: 'capitalize', fontSize: 13 }} onClick={async () => { await handleOpenEndereco(item) }}>Detalhes</Button></StyledTableCell>
                                      <StyledTableCell align="left">{item.email}</StyledTableCell>
                                      <StyledTableCell align="left">{phoneMask(item.telefone)}</StyledTableCell>
                                      <StyledTableCell align="left"><Button style={{ color: theme.palette.primary.main }} onClick={() => { handleArchive(item.id) }}>Arquivar</Button></StyledTableCell>
                                    </StyledTableRow>
                                  )
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )
                    : (
                      <h4>Não foi possível encontrar lojistas cadastrados</h4>
                    )
                }
              </>
            )
        }

      </S.ScreenContainer>
    </S.Container>
  )
}
