// Arquivo criado: 21/09/2023 às 19:54
import React from 'react'
import * as S from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'

interface Indicados {
  cnpj: string | null
  cpfResponsavel: string
  email: string
  id: number
  nomeFantasia: string
  razaoSocial: string
  telefone: string
}

interface Indicado {
  indicado: Indicados
}

interface User {
 email: string
 razaoSocial: string
 telefone: string
 id: number
 indicador: Indicado[]
}

interface Data {
  id: number
  usuarioId: number
  usuario: User
  valor: number
  finalizado: boolean | null
  iconInfo: string
}

export const Indicacao = (): JSX.Element => {

  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  // const [loading, setLoading] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<Data | null>(null)
  const [componentId, setComponentId] = React.useState<number | null>(null)
  const [open, setOpen] = React.useState(false)
  const [openInfo, setOpenInfo] = React.useState(false)
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [indicados, setIndicados] = React.useState<Indicado[]>([])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Dashboard'

      setLoading(true)
      const response = await backEnd('GET', 'resgate-cashback-lojista')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }

      setComponent(response.data)

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [userId, dispatch, get])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  interface HeadCell {
    id: keyof Data
    title: string
  }

  const titles: readonly HeadCell[] = [
    {
      id: 'valor',
      title: 'Valor'
    },
    {
      id: 'usuario',
      title: 'E-mail do Lojista'
    },
    {
      id: 'iconInfo',
      title: 'Informações'
    },
    {
      id: 'finalizado',
      title: 'Finalizado'
    }
  ]

  const handleOpenInfo = (component: Data): void => {
    setSelectedItem(component)
    setComponentId(component.id)
    setOpenInfo(true)
    setIndicados(component.usuario.indicador)
  }

  const handleClickOpen = (component: Data): void => {
    setSelectedItem(component)
    setComponentId(component.id)
    setOpen(true)
  }

  const handleClose = (): void => {
    setSelectedItem(null)
    setOpen(false)
  }

  const handleCloseInfo = (): void => {
    setSelectedItem(null)
    setOpenInfo(false)
  }

  const handleSave = async (): Promise<void> => {

    if (!componentId) return

    const response = await backEnd('PUT', `resgate-cashback-lojista/${componentId}`)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setGet(true)
    handleClose()

  }

  const phoneMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
  }

  return (
    <S.Container>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          Solicitação de Resgate
        </DialogTitle>
        <DialogContent>
          {
            selectedItem?.finalizado === null || !selectedItem?.finalizado
              ? (
                <div>
                  <h4>Valor de resgate: {selectedItem?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h4>
                  <h4>E-mail do lojista: {selectedItem?.usuario.email}</h4>
                  <p>Após o pagamento da bonificação por indicação para o lojista, este ítem deve ser marcado como finalizado. </p>
                  <p>Deseja alterar para finalizado agora?</p>
                </div>
              )
              : (
                <div>
                  <h4>Valor de resgate: {selectedItem?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h4>
                  <h4>E-mail do lojista: {selectedItem?.usuario.email}</h4>
                  <p>Esta solicitação de resgate já foi finalizada</p>
                </div>
              )
          }
        </DialogContent>
        {
          selectedItem?.finalizado === null || !selectedItem?.finalizado
            ? (
              <DialogActions>
                <Button onClick={handleSave}>Marcar como finalizado</Button>
                <Button onClick={handleClose}>Permanecer como não finalizado</Button>
              </DialogActions>
            )
            : (
              <DialogActions>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            )
        }
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openInfo}
        onClose={handleCloseInfo}
      >
        <DialogTitle>
          Informações sobre o Lojista
        </DialogTitle>
        <DialogContent>
          {
            selectedItem !== null && (
              <S.InformationContainer>
                <h5>Razão Social: {selectedItem.usuario.razaoSocial !== null ? selectedItem.usuario.razaoSocial : 'Não informado'}</h5>
                <h5>Telefone: {phoneMask(selectedItem.usuario.telefone)}</h5>
                <h5>E-mail: {selectedItem.usuario.email}</h5>
                <h5>Total de indicados: {selectedItem?.usuario.indicador.length}</h5>
              </S.InformationContainer>
            )
          }
          {
            (selectedItem?.usuario.indicador !== undefined && selectedItem?.usuario.indicador.length > 0) && (
              <div>
                <h4>Informações sobre os indicados:</h4>
                {
                  indicados.map((item, index) => (
                    <S.InformationContainer key={index}>
                      <h5>Razão Social: {item.indicado.razaoSocial !== null ? item.indicado.razaoSocial : 'Não informado'}</h5>
                      <h5>CNPJ: {item.indicado.cnpj !== null ? item.indicado.cnpj : 'Não informado'}</h5>
                      <h5>Nome Fantasia: {item.indicado.nomeFantasia}</h5>
                      <h5>CPF: {item.indicado.cpfResponsavel}</h5>
                      <h5>Telefone: {phoneMask(item.indicado.telefone)}</h5>
                      <h5>E-mail: {item.indicado.email}</h5>
                    </S.InformationContainer>
                  ))
                }
              </div>
            )
          }
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseInfo}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                {
                  component.length > 0
                    ? (
                      <>
                        <S.RowContainer className='top'>
                          <h4 style={{ color: '#000' }}>Fo{component.length !== 1 ? 'ram' : 'i'} encontrada{component.length !== 1 ? 's' : ''} {component.length} solicitaç{component.length !== 1 ? 'ões' : 'ão'} de resgate por indicação:</h4>
                        </S.RowContainer>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 700 }} stickyHeader>
                            <TableHead>
                              <TableRow>
                                {
                                  titles.map((item, index) => (
                                    <StyledTableCell key={index}>{item.title}</StyledTableCell>
                                  ))
                                }
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                component.map((item, index) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">{item.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</StyledTableCell>
                                      <StyledTableCell align="left">{item.usuario.email}</StyledTableCell>
                                      <StyledTableCell align="left">
                                        <S.RowContainer>
                                          <Button onClick={() => { handleOpenInfo(item) }}><LibraryBooksOutlinedIcon /></Button>
                                        </S.RowContainer>
                                      </StyledTableCell>
                                      <StyledTableCell align="left"><Button style={item.finalizado === null || !item.finalizado ? { color: 'red' } : { color: 'green' }} onClick={() => { handleClickOpen(item) }}>{item.finalizado === null || !item.finalizado ? 'Não' : 'Sim'}</Button></StyledTableCell>
                                    </StyledTableRow>
                                  )
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )
                    : (
                      <h4>Não foi possível encontrar solicitações de resgate de bonificação.</h4>
                    )
                }
              </>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
