import styled from 'styled-components'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
  h2 {
    margin: 10px 0;
  }
  .css-ivjue7-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  
    color: white;
  }
  h4 {
    color: #000;
  }
  label {
    color: #666;
  }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  label {
    color: #777;
  }
  .inputCreate {
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-self: center;
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  .button {
    margin: 30px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .top {
    margin: 15px auto;
    width: 100%;
  }
  .icon {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;

  }
`
export const LoadingContainer = styled.div`
  align-self: center;
  margin: 130px auto;
`

export const InputContianer = styled.div`
  width: 40%;
  align-self: flex-end;
`
export const SelectFileBox = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // align-self: stretch;
  justify-content: center;
  // margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  /* div {
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      text-align: center;
    }
  } */
  input[type="file"] {
    display: none;
  }
  label {
    width: 60%;
    text-align: center;
    display: block;
    padding: 7px 5px;
    border-radius: 4px;
    cursor: pointer;
    color: #F1B617;
    border: 1px solid #F1B617;
    margin: 5px 0;
    font-size: 13px;
  }
  `

export const DialogContent = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    align-self: center;
  `
