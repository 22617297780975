// Arquivo criado: 19/07/2023 às 12:29
import React from 'react'
import { Container } from '../Container'

interface Props {
  children: React.ReactNode
  isLogged: boolean
}

export const BaseContent = ({ children, isLogged }: Props): JSX.Element => {

  if (isLogged) {
    return (
      <Container>
        {children}
      </Container>
    )
  }

  return (
    <>
      {children}
    </>
  )

}
