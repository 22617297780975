// Arquivo criado: 28/09/2023 às 15:58
import React from 'react'
import * as S from './styles'
import orcaLogo from '../../assets/logo_horizontal_fundo_branco.png'

export const Exclusao = (): JSX.Element => {

  document.title = 'Exclusão de conta'

  return (
    <S.Container>
      <S.ScreenContainer>
        <S.LogoContainer>
          <S.LogoImg src={orcaLogo} />
        </S.LogoContainer>
        <S.TextContainer>
          <h2>Exclusão de conta</h2>
          <p>Para excluir sua conta OrçaBeer por favor entre em contato conosco<br /> através do e-mail <a href="mailto:orcabeer@kinghost.com.br">orcabeer@kinghost.com.br</a></p>
        </S.TextContainer>
      </S.ScreenContainer>
    </S.Container>
  )
}
