import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Home } from './pages/home'
import { Login } from './pages/login'
import { NotFound } from './pages/notFound'
import { useSelector } from 'react-redux'
import { type RootState } from './redux/store'
import { BaseContent } from './components/BaseContent'
import { Invite } from './pages/invite'
import { Fornecedores } from './pages/fornecedores'
import { Lojistas } from './pages/lojistas'
import { Produtos } from './pages/produtos'
import { Anuncios } from './pages/anuncios'
import { Categorias } from './pages/categorias'
import { Indicacao } from './pages/indicacao'
import { Exclusao } from './pages/excluirConta'
import { NaoEncontrado } from './pages/naoEncontrado'
import { ExclusaoDeConta } from './pages/exclusaoDeConta'
import { Unidades } from './pages/unidades'
import { Orcamentos } from './pages/orcamentos'
import { ComprasColetivas } from './pages/comprasColetivas'

export const RoutesController = (): JSX.Element => {

  const isLogged = useSelector((state: RootState) => state.user.isLogged)

  return (
    <BaseContent isLogged={isLogged}>
      <Routes>
        {
          isLogged
            ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Home />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/fornecedores" element={<Fornecedores />} />
                <Route path="/lojistas" element={<Lojistas />} />
                <Route path="/orcamentos" element={<Orcamentos />} />
                <Route path="/produtos" element={<Produtos />} />
                <Route path="/anuncios" element={<Anuncios />} />
                <Route path="/compras-coletivas" element={<ComprasColetivas />} />
                <Route path="/categorias" element={<Categorias />} />
                <Route path="/unidades" element={<Unidades />} />
                <Route path="/indicacoes" element={<Indicacao />} />
                <Route path="/exclusao-de-conta" element={<ExclusaoDeConta />} />
              </>
            )
            : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/indicacao" element={<Invite />} />
                <Route path="/excluir-conta" element={<Exclusao />} />
                <Route path="*" element={<NaoEncontrado />} />
              </>
            )
        }
      </Routes>
    </BaseContent>
  )
}
