// Arquivo criado: 19/07/2023 às 11:01
import { legacy_createStore as createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { rootReducer } from './reducers/root.reducer'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}

type RootState = ReturnType<typeof rootReducer>
type AppDispatch = typeof store.dispatch

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer)
const persistor = persistStore(store)

export { store, persistor, type RootState, type AppDispatch }
