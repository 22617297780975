// Arquivo criado: 22/09/2023 às 22:37
import React, { type SyntheticEvent } from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import { theme } from '../../config/theme'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Autocomplete from '@mui/material/Autocomplete'

interface Data {
  id: number
  categoria: {
    id: number
    categoria: string
  }
  diasRestantes: number
  imagens: string[]
  preco: number
  status: {
    id: number
    status: string
  }
  titulo: string
  usuario: {
    cnpj: string
    email: string
    nomeFantasia: string
  }
  valor: null | number
  icon: string
}

interface Fornecedor {
  nomeFantasia: string
  cnpj: string
  nomeResponsavel: string
  email: string
  telefone: string
  id: number
}

interface Categoria {
  categoria: string
  id: number
}

export const Anuncios = (): JSX.Element => {

  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  const [selectedItem, setSelectedItem] = React.useState<Data | undefined>(undefined)
  const [componentId, setComponentId] = React.useState<number | undefined>(undefined)
  const [status, setStatus] = React.useState<string>('')
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [openInfo, setOpenInfo] = React.useState(false)
  const [isSorted, setIsSorted] = React.useState(false)
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [openConfirmEdit, setOpenConfirmEdit] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [diasRestantes, setDiasRestantes] = React.useState(0)
  const [valor, setValor] = React.useState<number | null>(0)
  const [shownValor, setShownValor] = React.useState<string>('R$ 0,00')

  const [openCreateAnuncio, setOpenCreateAnuncio] = React.useState(false)
  const [titulo, setTitulo] = React.useState('')
  const [diasAdquiridos, setDiasAdquiridos] = React.useState<number | undefined>(undefined)
  const [categoriaId, setCategoriaId] = React.useState<number | undefined>(undefined)
  const [useCashback, setUseCashback] = React.useState(false)
  const [addPreco, setAddPreco] = React.useState<number | undefined>(undefined)
  const [shownAddPreco, setShownAddPreco] = React.useState<string>('R$ 0,00')
  const [addValor, setAddValor] = React.useState<number | undefined>(undefined)
  const [shownAddValor, setShownAddValor] = React.useState<string>('R$ 0,00')

  const [fornecedorId, setFornecedorId] = React.useState<number | undefined>(undefined)
  const [fornecedores, setFornecedores] = React.useState<Fornecedor[]>([])
  const [categorias, setCategorias] = React.useState<Categoria[]>([])
  const [shownCategoria, setShownCategoria] = React.useState('')
  const [clearCategoria, setClearCategoria] = React.useState(0)
  const [shownFornecedor, setShownFornecedor] = React.useState('')
  const [clearFornecedor, setClearFornecedor] = React.useState(0)

  const [file, setFile] = React.useState<File | undefined>(undefined)
  const [openConfirmCreate, setOpenConfirmCreate] = React.useState(false)
  const [loadingModal, setLoadingModal] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Anúncios'

      setLoading(true)
      const response = await backEnd('GET', 'anuncios/admin')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      setComponent(response.data)

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [dispatch, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      setLoading(true)
      const response = await backEnd('GET', 'usuarios/1')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      setFornecedores(response.data)
    }
    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      setLoading(true)
      const response = await backEnd('GET', 'categorias/produtos')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setCategorias(response.data)

    }
    void getData()
  }, [dispatch])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleClickOpen = (component: Data): void => {
    setSelectedItem(component)
    setComponentId(component.id)
    setOpen(true)
  }

  const handleClose = (): void => {
    setStatus('')
    setOpen(false)
  }

  const handleChange = (event: SelectChangeEvent): void => {
    setStatus(event.target.value)
  }

  const handleSave = async (): Promise<void> => {

    if (!componentId) return

    if (status === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione uma opção para continuar'
      }))
      return
    }

    setLoading(true)
    const response = await backEnd('PUT', `anuncio/${componentId}/${status}`)
    setLoading(false)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Status do anúncio atualizado com sucesso!',
      type: 'success'
    }))

    setSelectedItem(undefined)
    setComponentId(undefined)
    setStatus('')
    handleClose()
    setGet(true)

  }

  const handleOpenInfo = (component: Data): void => {
    setSelectedItem(component)
    setComponentId(component.id)
    setOpenInfo(true)
  }

  const handleOpenEditModal = (component: Data): void => {
    setSelectedItem(component)
    setComponentId(component.id)
    setDiasRestantes(component.diasRestantes)
    setValor(component.valor)
    if (component.valor !== null) {
      setShownValor(component.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
    }
    setOpenEditModal(true)
  }

  const handleOpenConfirmEdit = (): void => {
    setOpenConfirmEdit(true)
  }

  const handleCloseInfo = (): void => {
    setSelectedItem(undefined)
    setOpenInfo(false)
  }

  const handleCloseEditModal = (): void => {
    setSelectedItem(undefined)
    setOpenEditModal(false)
    setValor(0)
    setShownValor('R$ 0,00')
    setChecked(false)
  }

  const handleCloseConfirmEdit = (): void => {
    setOpenConfirmEdit(false)
  }

  const cnpjMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1/$2')
    value = value.replace(/(\d)(\d{2})$/, '$1-$2')
    return value
  }

  const handleSort = (key: keyof Data): void => {
    const sortedArray = [...component]
    if (key !== 'valor') {
      sortedArray.sort(function (a, b) {
        if (a[`${key}`] < b[`${key}`]) return -1
        if (a[`${key}`] > b[`${key}`]) return 1
        return 0
      })
    }
    setComponent(sortedArray)
  }

  const revertArrayByGeneric = (key: keyof Data): void => {
    const revertedArray = [...component]
    if (key !== 'valor') {
      revertedArray.sort(function (a, b) {
        if (a[`${key}`] < b[`${key}`]) return -1
        if (a[`${key}`] > b[`${key}`]) return 1
        return 0
      })
    }
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByTitulo = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.titulo.localeCompare(b.titulo, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByTitulo = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.titulo.localeCompare(b.titulo, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByCategoria = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.categoria.categoria.localeCompare(b.categoria.categoria, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByCategoria = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.categoria.categoria.localeCompare(b.categoria.categoria, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByUsuario = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.usuario.nomeFantasia.localeCompare(b.usuario.nomeFantasia, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByUsuario = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.usuario.nomeFantasia.localeCompare(b.usuario.nomeFantasia, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByStatus = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.status.status.localeCompare(b.status.status, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByStatus = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.status.status.localeCompare(b.status.status, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const handleCashback = (): void => {
    setChecked(!checked)
  }

  const handleDiasRestantes = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDiasRestantes(Number(event.target.value))
  }

  const handleValor = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userInput = event.target.value.replace(/[^0-9]/g, '')

    if (userInput === '') {
      setShownValor('R$ 0,00')
      setValor(0)
    } else {
      const userInputAsNumber = parseInt(userInput, 10) / 100
      const formattedNumber: string = `R$ ${userInputAsNumber.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`

      setShownValor(formattedNumber)
      setValor(userInputAsNumber)
    }
  }

  const handleSaveChanges = async (): Promise<void> => {
    if (!diasRestantes) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a quantidade de dias de duração do anúncio para continuar',
        type: 'error'
      }))
      return
    }

    const body = {
      useCashback: checked,
      diasAdquiridos: diasRestantes,
      valor: valor ?? 0
    }

    if (componentId === undefined) return

    setLoadingModal(true)
    const response = await backEnd('PUT', `anuncio/${componentId}/renovar`, body)
    setLoadingModal(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Anúncio atualizado com sucesso!',
      type: 'success'
    }))

    setComponentId(undefined)
    setOpenConfirmEdit(false)
    setOpenEditModal(false)
    setValor(null)
    setShownValor('R$ 0,00')
    setChecked(false)
    setGet(true)
  }

  const handleOpenCreateAnuncio = (): void => {
    setOpenCreateAnuncio(true)
  }

  const handleCloseCreateAnuncio = (): void => {
    setOpenCreateAnuncio(false)
    setAddValor(undefined)
    setShownAddValor('R$ 0,00')
    setUseCashback(false)
    setAddPreco(undefined)
    setShownAddPreco('R$ 0,00')
    setTitulo('')
    setDiasAdquiridos(undefined)
    setClearFornecedor(clearFornecedor + 1)
    setClearCategoria(clearCategoria + 1)
    setFornecedorId(undefined)
    setShownFornecedor('')
    setCategoriaId(undefined)
    setShownCategoria('')
    setFile(undefined)
  }

  const handleOpenConfirmCreate = (): void => {
    setOpenConfirmCreate(true)
  }

  const handleCloseConfirmCreate = (): void => {
    setOpenConfirmCreate(false)
  }

  const handleCashbackOnCreate = (): void => {
    setUseCashback(!useCashback)
  }

  const handleTitulo = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitulo(event.target.value)
  }

  const handleDiasAdquiridos = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDiasAdquiridos(Number(event.target.value))
  }

  const handleAddPreco = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userInput = event.target.value.replace(/[^0-9]/g, '')

    if (userInput === '') {
      setShownAddPreco('R$ 0,00')
      setAddPreco(0)
    } else {
      const userInputAsNumber = parseInt(userInput, 10) / 100
      const formattedNumber: string = `R$ ${userInputAsNumber.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`

      setShownAddPreco(formattedNumber)
      setAddPreco(userInputAsNumber)
    }
  }

  const handleAddValor = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userInput = event.target.value.replace(/[^0-9]/g, '')

    if (userInput === '') {
      setShownAddValor('R$ 0,00')
      setAddValor(0)
    } else {
      const userInputAsNumber = parseInt(userInput, 10) / 100
      const formattedNumber = `R$ ${userInputAsNumber.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`

      setShownAddValor(formattedNumber)
      setAddValor(userInputAsNumber)
    }
  }

  const handleCreateAnuncio = async (): Promise<void> => {

    if (!titulo || titulo === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o título do anúncio para continuar',
        type: 'error'
      }))
      return
    }

    if (!diasAdquiridos) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a quantidade de dias de duração do anúncio para continuar',
        type: 'error'
      }))
      return
    }

    if (!addPreco) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o preço do produto anunciado para continuar',
        type: 'error'
      }))
      return
    }

    if (!file) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione uma imagem para continuar',
        type: 'error'
      }))

      return
    }

    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file as Blob)
      reader.onload = () => { resolve(reader.result) }
      reader.onerror = error => { reject(error) }
    })

    if (!base64) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Ocorreu um erro ao tentar enviar a imagem. Por favor, tente novamente',
        type: 'error'
      }))

      return
    }

    const body = {
      files: [
        {
          base64,
          size: file.size,
          name: file.name,
          type: file.type
        }
      ],
      anuncio: {
        titulo,
        categoriaId,
        diasAdquiridos,
        useCashback,
        preco: addPreco,
        valor: addValor ?? 0
      }
    }

    if (!fornecedorId) return

    setLoadingModal(true)
    const response = await backEnd('POST', `anuncio/${fornecedorId}`, body)
    setLoadingModal(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Anúncio criado com sucesso!',
      type: 'success'
    }))

    setOpenConfirmCreate(false)
    setOpenCreateAnuncio(false)
    setAddValor(undefined)
    setShownAddValor('R$ 0,00')
    setUseCashback(false)
    setAddPreco(undefined)
    setShownAddPreco('R$ 0,00')
    setTitulo('')
    setDiasAdquiridos(undefined)
    setClearFornecedor(clearFornecedor + 1)
    setClearCategoria(clearCategoria + 1)
    setFornecedorId(undefined)
    setShownFornecedor('')
    setCategoriaId(undefined)
    setShownCategoria('')
    setFile(undefined)
    setGet(true)
  }

  const handleFornecedorSelect = (event: SyntheticEvent<Element, Event>, value: Fornecedor | null): void => {
    const selected = value
    if (!selected) return
    const fornecedor = fornecedores.find(item => item.nomeFantasia === selected.nomeFantasia)

    if (!fornecedor) return
    setFornecedorId(fornecedor.id)

    setShownFornecedor(fornecedor.nomeFantasia)
  }

  const handleCategoriaSelect = (event: SyntheticEvent<Element, Event>, value: Categoria | null): void => {
    const selected = value
    if (!selected) return
    const categoria = categorias.find(item => item.categoria === selected.categoria)

    if (!categoria) return
    setCategoriaId(categoria.id)

    setShownCategoria(categoria.categoria)
  }

  const defaultPropsCategoria = {
    options: categorias,
    getOptionLabel: (option: Categoria) => option.categoria
  }

  const defaultPropsFornecedor = {
    options: fornecedores,
    getOptionLabel: (option: Fornecedor) => option.nomeFantasia
  }

  const uploadImageValidation = (file: File | undefined): void => {
    if (file) {

      const isImage1 = /(\.jpeg)$/i
      const isImage2 = /(\.png)$/i
      const isImage3 = /(\.jpg)$/i

      if (!isImage1.exec(file.name) && !isImage2.exec(file.name) && !isImage3.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .jpg, .jpeg ou .png',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      const fileSize = file.size

      if (fileSize > 2000000) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A imagem selecionada é muito grande. O tamanho máximo é de 2MB',
          type: 'error'
        }))

        return
      }

      setFile(file)
    }
  }

  return (
    <S.Container>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {selectedItem !== undefined ? selectedItem.titulo : 'Anúncio'}
        </DialogTitle>
        <DialogContent>
          {
            selectedItem?.status.id === 1
              ? (
                <div>
                  <InputLabel sx={{ color: '#000' }}>Selecione uma opção</InputLabel>
                  <Select
                    value={status}
                    // label="Status"
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value={2}>Aprovado</MenuItem>
                    <MenuItem value={6}>Reprovado</MenuItem>
                  </Select>
                </div>
              )
              : (
                <h4>Status do anúncio: {selectedItem?.status.status}</h4>
              )
          }
        </DialogContent>
        <DialogActions>
          {
            selectedItem?.status.id === 1
              ? (
                <Button onClick={handleSave}>Salvar</Button>
              )
              : (
                <Button onClick={handleClose}>Fechar</Button>
              )
          }
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openInfo}
        onClose={handleCloseInfo}
      >
        <DialogTitle id="alert-dialog-title">
          Informações sobre o Fornecedor
        </DialogTitle>
        <DialogContent>
          {
            selectedItem !== undefined && (
              <div>
                <h5>Nome Fantasia: {selectedItem.usuario.nomeFantasia}</h5>
                <h5>CNPJ: {cnpjMask(selectedItem.usuario.cnpj)}</h5>
                <h5>E-mail: {selectedItem.usuario.email}</h5>
              </div>
            )
          }
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseInfo}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openEditModal}
        onClose={handleCloseEditModal}
      >
        <DialogTitle id="alert-dialog-title">
          Editar anúncio - {selectedItem?.titulo}
        </DialogTitle>
        <DialogContent>
          {
            selectedItem !== undefined && (
              <div>
                <p>Fornecedor: <strong>{selectedItem.usuario.nomeFantasia}</strong> </p>
                {
                  selectedItem.status.id !== 1 && selectedItem.status.id !== 5 && (
                    <em>Este anúncio não pode ser editado no momento.</em>
                  )
                }
              </div>
            )
          }
          {
            loadingModal
              ? (
                <S.LoadingContainer>
                  <CircularProgress />
                </S.LoadingContainer>
              )
              : (
                <>
                  <S.RowContainer>
                    <S.ColumnContainer width={90}>
                      <S.RowContainer>
                        <h5>Utilizar bônus?</h5>
                        <S.InputContianer>
                          <Switch
                            checked={checked}
                            onChange={handleCashback}
                            disabled={selectedItem?.status.id !== 1 && selectedItem?.status.id !== 5}
                          />
                        </S.InputContianer>
                      </S.RowContainer>
                      <S.RowContainer>
                        <h5>Total de dias:</h5>
                        <S.InputContianer>
                          <TextField type='number' fullWidth className='input' size='small' id='diasRestantes' name='diasRestantes' value={diasRestantes} onChange={handleDiasRestantes} label='Duração' disabled={selectedItem?.status.id !== 1 && selectedItem?.status.id !== 5} />
                        </S.InputContianer>
                      </S.RowContainer>
                      <S.RowContainer>
                        <h5>Valor por dia de anúncio:</h5>
                        <S.InputContianer>
                          <TextField fullWidth className='input' size='small' id='valor' name='valor' value={shownValor} onChange={handleValor} label='Valor' disabled={selectedItem?.status.id !== 1 && selectedItem?.status.id !== 5} />
                        </S.InputContianer>
                      </S.RowContainer>
                    </S.ColumnContainer>
                  </S.RowContainer>
                </>
              )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpenConfirmEdit}>Salvar</Button>
          <Button onClick={handleCloseEditModal}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openConfirmEdit}
        onClose={handleCloseConfirmEdit}
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar edição?
        </DialogTitle>
        <DialogContent>
          <p>Ao salvar, se o valor informado para o anúncio for maior que zero será gerada a cobrança ao usuário no valor informado e o anúncio ficará no status de <strong>Aguardando pagamento</strong>. Assim que o pagamento for efetuado, o usuário escolherá o dia no qual o anúncio começará a ser exibido. Caso o valor seja zero, o status a qual o anúncio ficará será <strong>Aprovado, pagamento confirmado</strong></p>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingModal} onClick={handleSaveChanges}>Salvar</Button>
          <Button disabled={loadingModal} onClick={handleCloseConfirmEdit}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openCreateAnuncio}
        onClose={handleCloseCreateAnuncio}
      >
        <DialogTitle id="alert-dialog-title">
          Criar Novo Anúncio
        </DialogTitle>
        <S.DialogContent>
          {
            loadingModal
              ? (
                <S.LoadingContainer>
                  <CircularProgress />
                </S.LoadingContainer>
              )
              : (
                <>
                  <S.RowContainer>
                    <S.ColumnContainer width={99}>
                      <S.RowContainer style={{ justifyContent: 'flex-start', marginBottom: 15 }}>
                        <h5>Utilizar bônus?</h5>
                        <Switch
                          checked={useCashback}
                          onChange={handleCashbackOnCreate}
                          style={{ alignSelf: 'center' }}
                        />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <Autocomplete
                          {...defaultPropsCategoria}
                          key={clearCategoria}
                          disablePortal
                          id="categorias"
                          inputValue={shownCategoria}
                          onInputChange={(event, newInputValue) => {
                            setShownCategoria(newInputValue)
                          }}
                          onChange={handleCategoriaSelect}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: false
                                },
                                {
                                  name: 'preventOverflow',
                                  enabled: false
                                }
                              ]
                            }
                          }}
                          sx={{ width: '70%' }}
                          renderInput={(params) => <TextField {...params} className='select input' size='small' id='categorias' name='categorias' disabled={categorias.length === 0} label='Escolher Categoria do Produto' />}
                        />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <Autocomplete
                          {...defaultPropsFornecedor}
                          key={clearFornecedor}
                          disablePortal
                          id="fornecedores"
                          inputValue={shownFornecedor}
                          onInputChange={(event, newInputValue) => {
                            setShownFornecedor(newInputValue)
                          }}
                          onChange={handleFornecedorSelect}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: false
                                },
                                {
                                  name: 'preventOverflow',
                                  enabled: false
                                }
                              ]
                            }
                          }}
                          sx={{ width: '70%' }}
                          renderInput={(params) => <TextField {...params} className='select input' size='small' id='fornecedores' name='fornecedores' disabled={fornecedores.length === 0} label='Escolher Fornecedor' />}
                        />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField fullWidth className='input' size='small' id='titulo' name='titulo' value={titulo} onChange={handleTitulo} label='Título' />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField type='number' fullWidth className='input' size='small' id='diasAdquiridos' name='diasAdquiridos' value={diasAdquiridos} onChange={handleDiasAdquiridos} label='Duração' />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField fullWidth className='input' size='small' id='addPreco' name='addPreco' value={shownAddPreco} onChange={handleAddPreco} label='Preço do Produto' />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField fullWidth className='input' size='small' id='addValor' name='addValor' value={shownAddValor} onChange={handleAddValor} label='Valor do Anúncio' />
                      </S.RowContainer>
                    </S.ColumnContainer>
                  </S.RowContainer>
                  <S.RowContainer>
                    <S.SelectFileBox>
                      <label htmlFor='file'>SELECIONAR IMAGEM</label>
                      <input id='file' name='file' type='file' value={''} required accept='image/png, image/jpg, image/jpeg' placeholder='Anexar Arquivo' onChange={(e) => { uploadImageValidation(e.target.files?.[0]) }} />
                    </S.SelectFileBox>
                  </S.RowContainer>
                  {
                    (file) && (
                      <>
                        <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#F1B61744', borderRadius: 8, marginBottom: 10 }}>
                          <p><strong>Arquivo selecionado:</strong> {file.name}</p>
                        </S.RowContainer>
                        <S.RowContainer style={{ justifyContent: 'space-evenly' }}>
                          <Button variant='outlined' onClick={() => { setFile(undefined) }}>Excluir</Button>
                        </S.RowContainer>
                      </>
                    )
                  }
                </>
              )
          }
        </S.DialogContent>
        <DialogActions>
          <Button onClick={handleOpenConfirmCreate}>Salvar</Button>
          <Button onClick={handleCloseCreateAnuncio}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openConfirmCreate}
        onClose={handleCloseConfirmCreate}
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar novo anúncio?
        </DialogTitle>
        <DialogContent>
          <p>Ao salvar, se o valor informado para o anúncio for maior que zero será gerada a cobrança ao usuário no valor informado e o anúncio ficará no status de <strong>Aguardando pagamento</strong>. Assim que o pagamento for efetuado, o usuário escolherá o dia no qual o anúncio começará a ser exibido. Caso o valor seja zero, o status a qual o anúncio ficará será <strong>Aprovado, pagamento confirmado</strong></p>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingModal} onClick={handleCreateAnuncio}>Confirmar</Button>
          <Button disabled={loadingModal} onClick={handleCloseConfirmCreate}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                <S.RowContainer className='top'>
                  {
                    component.length > 0 && (
                      <h4>Fo{component.length !== 1 ? 'ram' : 'i'} encontrado{component.length !== 1 ? 's' : ''} {component.length} anúncio{component.length !== 1 ? 's' : ''}:</h4>
                    )
                  }
                  <Button variant='outlined' onClick={handleOpenCreateAnuncio}>Criar novo anúncio</Button>
                </S.RowContainer>
                {
                  component.length > 0
                    ? (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? sortByTitulo()
                                  : revertArrayByTitulo()
                              }}>Título</Button></StyledTableCell>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? sortByCategoria()
                                  : revertArrayByCategoria()
                              }}>Categoria</Button></StyledTableCell>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? handleSort('diasRestantes')
                                  : revertArrayByGeneric('diasRestantes')
                              }}>Duração</Button></StyledTableCell>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? handleSort('preco')
                                  : revertArrayByGeneric('preco')
                              }}>Preço do produto</Button></StyledTableCell>
                              <StyledTableCell><Button>Imagem</Button></StyledTableCell>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? sortByUsuario()
                                  : revertArrayByUsuario()
                              }}>Fornecedor</Button></StyledTableCell>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? sortByStatus()
                                  : revertArrayByStatus()
                              }}>Status</Button></StyledTableCell>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? sortByStatus()
                                  : revertArrayByStatus()
                              }}>Editar</Button></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              component.map((item, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">{item.titulo}</StyledTableCell>
                                    <StyledTableCell align="left">{item.categoria.categoria}</StyledTableCell>
                                    <StyledTableCell align="left">{item.diasRestantes} dia{item.diasRestantes !== 1 ? 's' : ''}</StyledTableCell>
                                    <StyledTableCell align="left">{item.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</StyledTableCell>
                                    <StyledTableCell align="left"><a href={item.imagens[0]} target='_blank' rel="noreferrer">Abrir imagem</a></StyledTableCell>
                                    <StyledTableCell align="left">{item.usuario.nomeFantasia} <Button className='icon' onClick={() => { handleOpenInfo(item) }}><LibraryBooksOutlinedIcon /></Button></StyledTableCell>
                                    <StyledTableCell align="left"><Button style={item.status.id === 1 ? { color: theme.palette.primary.main } : item.status.id === 2 || item.status.id === 3 || item.status.id === 4 ? { color: 'green' } : item.status.id === 6 || item.status.id === 7 ? { color: 'red' } : { color: '#666' }} onClick={() => { handleClickOpen(item) }}>{item.status.status}</Button></StyledTableCell>
                                    <StyledTableCell align="center"><Button className='icon' onClick={() => { handleOpenEditModal(item) }}><ModeOutlinedIcon /></Button></StyledTableCell>
                                  </StyledTableRow>
                                )
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                    : (
                      <h4>Não foram encontrados anúncios em análise</h4>
                    )
                }
              </>
            )
        }

      </S.ScreenContainer>
    </S.Container>
  )
}
