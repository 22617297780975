// Arquivo criado: 11/06/2024 às 21:51
import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { backEnd } from '../../utils/backend.util'
import { useDispatch } from 'react-redux'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'

interface Props {
  userId: number | null
  setUserId: React.Dispatch<React.SetStateAction<number | null>>
}

export const DialogArchiveUser = ({ userId, setUserId }: Props): JSX.Element => {

  const [loadingModal, setLoadingModal] = React.useState<boolean>(false)

  const dispatch = useDispatch()

  const handleCloseConfirmEdit = (): void => {

    if (loadingModal) return

    setUserId(null)
  }

  const handleSaveChanges = async (): Promise<void> => {

    if (loadingModal || !userId) return

    setLoadingModal(true)
    const response = await backEnd('PUT', `usuario/${userId}/arquivar`)
    setLoadingModal(false)
    setUserId(null)

    dispatch(setOpenGlobalMessageAction({
      message: response.msg,
      type: response.ok ? 'success' : 'error'
    }))

  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={!!userId}
      onClose={handleCloseConfirmEdit}
    >
      <DialogTitle id="alert-dialog-title">
        Confirmar edição?
      </DialogTitle>
      <DialogContent>
        <p>
          Ao continuar, as seguintes ações serão realizadas:
          <br />
          <br />
          - Todas as sessões ativas do usuário serão encerradas.
          <br />
          - O usuário não poderá mais acessar o App.
          <br />
          - Todas as propostas em andamento serão encerradas.
          <br />
          - <b>Caso fornecedor</b>: Seus funcionários terão as sessões encerradas e não poderão mais acessar o App.
          <br />
          - <b>Caso fornecedor</b>: Todos os anúncios ativos serão encerrados.
          <br />
          - <b>Caso lojista</b>: Todos os pedidos em andamento serão encerrados.
          <br />
          - <b>Caso lojista</b>: Todos os resgates pendentes serão cancelados.
        </p>
      </DialogContent>
      <DialogActions>
        <Button disabled={loadingModal} onClick={handleSaveChanges}>Salvar</Button>
        <Button disabled={loadingModal} onClick={handleCloseConfirmEdit}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  )

}
