import { type Action } from 'redux'
import { type IUser } from '../../interfaces/IUser'

export interface UserPayload {
  perfil: IUser | null
  isLogged: boolean
  token: string | null
}

export const SET_USER = 'SET_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const NOTIFICATION_USER = 'NOTIFICATION_USER'

export interface SetUserAction extends Action<typeof SET_USER> {
  payload: IUser
}

export interface SetLoginUser extends Action<typeof LOGIN_USER> {
  payload: Omit<UserPayload, 'perfil'>
}

export interface SetNotificationUser extends Action<typeof NOTIFICATION_USER> {
  payload: number
}

export const setUser = (User: IUser): SetUserAction => ({
  type: SET_USER,
  payload: User
})

export const setLoginUser = (data: Omit<UserPayload, 'perfil'>): SetLoginUser => ({
  type: LOGIN_USER,
  payload: data
})

export const setNotificationUser = (data: number): SetNotificationUser => ({
  type: NOTIFICATION_USER,
  payload: data
})
