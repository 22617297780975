import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
 

  h2 {
    margin: 10px 0;
  }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .input {
    margin: 7px 0;
    width: 100%;
  }
  label {
    color: #666;
  }
  button {
    width: 80%;
    margin-bottom: 8px;
  }
  .infos {
     margin-top: 20px;
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h4 {
    color: #000;
  }
  .button {
    margin: 30px 0;
    cursor: pointer;
    width: 45%;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2{
    color: ${theme.palette.primary.main};
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .top {
    margin: 15px 0;
  }
  .main {
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }
`

export const LoadingContainer = styled.div`
  align-self: center;
  margin: 100px auto;
`
