import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#F1B617'
    },
    secondary: {
      main: '#000000'
    },
    background: {
      default: '#fff'
    },
    text: {
      primary: '#000',
      secondary: '#fff'
    }
  },
  shape: {
    borderRadius: 5
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  }
})
