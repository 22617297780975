// Arquivo criado: 24/07/2023 às 16:36
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch, type RootState } from '../../redux/store'
import { useSnackbar } from 'notistack'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { theme } from '../../config/theme'

export const GlobalMessage: React.FC = () => {

  const state = useSelector((state: RootState) => state.globalMessage.data)
  const dispatch = useDispatch<AppDispatch>()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = React.useState(false)

  const handleClose = React.useCallback((): void => {

    dispatch(setOpenGlobalMessageAction({
      message: '',
      modal: undefined,
      type: 'error'
    }))
    setOpen(false)
  }, [dispatch])

  React.useEffect(() => {
    if (!state.message) return

    if (!state.modal) {
      enqueueSnackbar(state.message, {
        variant: state.type,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        autoHideDuration: 5000,
        onClose: handleClose
      })
      return
    }

    setOpen(true)

  }, [enqueueSnackbar, handleClose, state])

  const handleRecuse = (): void => {
    if (!state.modal?.onClose) return

    state.modal.onClose.action()
    handleClose()
  }

  const handleConfirm = (): void => {
    if (state.modal?.onConfirm?.action) {
      state.modal.onConfirm.action()
    }

    handleClose()
  }

  return (
    <>
      {
        state.modal &&
          (
            <Dialog
              open={open}
              onClose={state.modal.dimissable === false ? undefined : handleClose }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{state.modal.title}</DialogTitle>
              <DialogContent>
                <DialogContentText color={theme.palette.text.primary} id="alert-dialog-description">
                  {state.message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {
                  state.modal.onClose && (
                    <Button variant='outlined' onClick={handleRecuse}>
                      {state.modal.onClose.text}
                    </Button>
                  )
                }
                <Button variant='outlined' onClick={handleConfirm} autoFocus>
                  {state.modal.onConfirm?.text ?? 'Ok'}
                </Button>
              </DialogActions>
            </Dialog>
          )
      }
    </>
  )
}
