// Arquivo criado: 24/08/2023 às 12:11
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { setLoginUser, setUser } from '../../redux/actions/user.action'
import { useNavigate } from 'react-router-dom'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import orcaLogo from '../../assets/logo_horizontal_fundo_branco.png'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

export const Login = (): JSX.Element => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [showPassword, setShowPassword] = React.useState(false)

  React.useEffect(() => {
    document.title = 'OrçaBeer Administrador - Login'

  }, [])

  const handleLogin = async (): Promise<void> => {

    if (!email || email === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Digite o e-mail cadastrado para continuar',
        type: 'error'
      }))
      return
    }

    if (!password || password === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Digite a senha cadastrada para continuar',
        type: 'error'
      }))
      return
    }

    const data = {
      email,
      senha: password
    }

    const response = await backEnd('POST', 'admin/login', data)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        modal: {
          title: 'Não foi possível fazer o login'
        },
        message: response.msg,
        type: 'error'
      }))

      setEmail('')
      setPassword('')
      return
    }

    const saveInfos = (): void => {
      const { token, ...user } = response.data

      dispatch(setLoginUser({
        isLogged: true,
        token
      }))

      dispatch(setUser(user))

      navigate('/')

    }

    saveInfos()
  }

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value)
  }

  const handleClickShowPassword = (): void => { setShowPassword((show) => !show) }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }

  return (
    <S.Container>
      <S.ScreenContainer>
        <S.LogoContainer>
          <img src={orcaLogo} width={'40%'} />
        </S.LogoContainer>
        <S.FormContainer>
          <S.TextContainer>
            <TextField className='input' required id='email' name='email' label='E-mail' value={email} onChange={handleEmail} />
            <TextField className='input' required id='senha' name='senha' label='Senha' type={showPassword ? 'text' : 'password'} value={password} onChange={handlePassword}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            <Button className='orcaButton' onClick={handleLogin} variant='outlined'>Entrar</Button>
          </S.TextContainer>
        </S.FormContainer>
      </S.ScreenContainer>
    </S.Container>
  )
}
