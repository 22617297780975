// Arquivo criado: 19/07/2023 às 11:21

import { type SetNotificationUser, type SetLoginUser, type SetUserAction, type UserPayload } from '../actions/user.action'

const initialState: UserPayload = {
  perfil: null,
  isLogged: false,
  token: null
}

export const userReducer = (
  state = initialState,
  action: SetUserAction | SetLoginUser | SetNotificationUser
): UserPayload => {
  switch (action.type) {
  case 'SET_USER':
    return {
      ...state,
      perfil: action.payload
    }
  case 'LOGIN_USER':
    return {
      ...state,
      ...action.payload
    }
  case 'NOTIFICATION_USER':
    return {
      ...state,
      perfil: {
        ...state.perfil as any,
        notifications: action.payload
      }
    }
  default:
    return state
  }
}
