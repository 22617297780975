// Arquivo criado: 22/09/2023 às 18:33
import React from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { Checkbox, FormControlLabel } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

interface IUnidade {
  id: number
  unidade: string
  plural: string
  curto: string
}

interface Data {
  nome: string
  id: number
  icons: string
  qtdSelecionado: number
  unidade: null | IUnidade
}

interface TempData {
  nome: string
  id: number
  categoriaId: number
  icons: string
  unidade: null | IUnidade
}

interface TempDataCategoria {
  nome: string
  id: number
  categoriaId: number
  icons: string
  categoria: string
  unidade: null | IUnidade
}

interface ICategoria {
  id: number
  deletado: boolean
  categoria: string
}

interface ISelectedCategoria {
  categoria: ICategoria
  checked: boolean
}

export const Produtos = (): JSX.Element => {

  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  const [componentId, setComponentId] = React.useState<number | null>(null)
  const [tempComponent, setTempComponent] = React.useState<TempData[]>([])
  const [loading, setLoading] = React.useState(false)
  const [categorias, setCategorias] = React.useState<ICategoria[]>([])
  const [categoria, setCategoria] = React.useState(2)
  const [shownCategoria, setShownCategoria] = React.useState('')
  const [isSorted, setIsSorted] = React.useState(false)
  const [showTemp, setShowTemp] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [editableItem, setEditableItem] = React.useState<Data | null>(null)
  const [selectedCategorias, setSelectedCategorias] = React.useState<ISelectedCategoria[]>([])
  const [categoriaChecked, setCategoriaChecked] = React.useState('')
  const [categoriasId, setCategoriasId] = React.useState<number[]>([])
  const [nameProduto, setNameProduto] = React.useState(false)
  const [chooseName, setChooseName] = React.useState(false)
  const [chooseUnit, setChooseUnit] = React.useState(false)
  const [produto, setProduto] = React.useState('')
  const [willDelete, setWillDelete] = React.useState(false)
  const [editableTemp, setEditableTemp] = React.useState<TempData | null>(null)
  const [openEditTemp, setOpenEditTemp] = React.useState(false)
  const [choosenProduto, setChoosenProduto] = React.useState<Data | null>(null)
  const [shownChoosenProduto, setShownChoosenProduto] = React.useState('')
  const [tempCategoria, setTempCategoria] = React.useState(0)
  const [shownTempCategoria, setShownTempCategoria] = React.useState('')
  const [tempCategorias, setTempCategorias] = React.useState<TempDataCategoria[]>([])
  const [get, setGet] = React.useState(false)
  const [unidades, setUnidades] = React.useState<IUnidade[]>([])
  const [unidade, setUnidade] = React.useState<number | null>(null)
  const [shownUnidade, setShownUnidade] = React.useState('')

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Produtos'

      setLoading(true)
      const response = await backEnd('GET', `produtos/${categoria}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      if (response.data !== undefined) {
        setComponent(response.data)

      } else {
        setCategoria(0)
        setComponent([])
      }

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [categoria, dispatch, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const response = await backEnd('GET', 'produtos-temp')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      if (response.data !== undefined) {
        setTempComponent(response.data)
      }
      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [dispatch, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      const response = await backEnd('GET', 'categorias/produtos')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setCategorias(response.data)

    }
    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      const response = await backEnd('GET', 'unidades/produtos')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setUnidades(response.data)

    }
    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = (): void => {

      const temp = tempComponent.map((item: TempData) => {
        const categoria = categorias.find(elem => elem.id === item.categoriaId)
        if (!categoria) {
          return { nome: item.nome, id: item.id, categoriaId: item.categoriaId, icons: item.icons, categoria: '-', unidade: item.unidade }
        } else {
          return { nome: item.nome, id: item.id, categoriaId: item.categoriaId, icons: item.icons, categoria: categoria.categoria, unidade: item.unidade }
        }
      })
      setTempCategorias(temp)
    }
    getData()
  }, [categorias, tempComponent])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },

    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleSelectCategoria = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const categoria = categorias.find(item => item.id === id)
    setCategoria(id)

    if (!categoria) return
    setShownCategoria(categoria.categoria)
    setVisible(true)
    setShowTemp(false)
  }

  const handleSelectUnidade = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const unidade = unidades.find(item => item.id === id)
    setUnidade(id)

    if (!unidade) return
    setShownUnidade(unidade.unidade)
  }

  const handleCloseEdit = (): void => {
    setProduto('')
    setComponentId(null)
    setEditableItem(null)
    setOpenEdit(false)
    setUnidade(null)
    setShownUnidade('')
  }

  const handleCloseDelete = (): void => {
    setProduto('')
    setComponentId(null)
    setEditableItem(null)
    setWillDelete(false)
    setOpenDelete(false)
  }

  const handleCloseCreate = (): void => {
    setProduto('')
    setSelectedCategorias([])
    setCategoriasId([])
    setOpenCreate(false)
    setNameProduto(false)
    setChooseName(false)
    setChooseUnit(false)
    setCategoriaChecked('')
    setUnidade(null)
    setShownUnidade('')
  }

  const handleOpenCreate = (): void => {
    setOpenCreate(true)
  }

  const handleCloseEditTemp = (): void => {
    setOpenEditTemp(false)
    setEditableTemp(null)
    setTempCategoria(0)
    setShownTempCategoria('')
    setChoosenProduto(null)
    setShownChoosenProduto('')
  }

  const handleCategoriaOnCreate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setChooseName(false)
    setChooseUnit(false)
    const id = Number(e.target.value)
    const categoria = categorias.find(item => item.id === id)
    if (!categoria) return
    setCategoriaChecked(categoria.categoria)
    const selectedCategoria = selectedCategorias.find(item => item.categoria === categoria)
    if (!selectedCategoria) {
      setSelectedCategorias(
        [
          ...selectedCategorias,
          { categoria, checked: true }
        ]
      )
    }
    setNameProduto(true)
  }

  const dropCategoria = (e: React.ChangeEvent<HTMLInputElement>, id: number): void => {
    setChooseName(false)
    setChooseUnit(false)
    const categoria = selectedCategorias.find(item => item.categoria.id === id)
    if (!categoria) return
    setSelectedCategorias(
      selectedCategorias.filter(a =>
        a.categoria.id !== categoria.categoria.id
      )
    )
  }

  const continueCreating = (): void => {
    setChooseUnit(true)
    setCategoriasId(
      selectedCategorias.map((item) => (
        item.categoria.id
      ))
    )
  }

  const continueCreatingFinal = (): void => {
    setChooseName(true)
  }

  const handleProduto = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setProduto(event.target.value)
  }

  const handleSave = async (): Promise<void> => {
    if (!produto) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o produto para continuar',
        type: 'error'
      }))
      return
    }

    if (produto.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O produto deve conter no máximo 255 caracteres',
        type: 'error'
      }))
      return
    }

    if (categoriasId.length < 1) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe pelo menos 1 categoria para continuar',
        type: 'error'
      }))
    }

    if (!unidade) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a unidade do produto para continuar',
        type: 'error'
      }))
      return
    }

    const body = {
      produto,
      categorias: categoriasId,
      unidadeId: unidade
    }

    const response = await backEnd('POST', 'produto', body)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setShownCategoria('')
    setShownUnidade('')
    setUnidade(null)
    setProduto('')
    setSelectedCategorias([])
    setCategoriasId([])
    setCategoriaChecked('')
    setOpenCreate(false)
    setGet(true)
  }

  const showTables = (): void => {
    setShowTemp(!showTemp)
    setVisible(true)
  }
  const handleDelete = (component: Data): void => {
    setEditableItem(component)
    setComponentId(component.id)
    setOpenDelete(true)
  }

  const handleSaveDelete = async (): Promise<void> => {

    if (componentId === null) return

    const response = await backEnd('DELETE', `produto/${componentId}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setShownCategoria('')
    setShownUnidade('')
    setUnidade(null)
    setProduto('')
    setSelectedCategorias([])
    setCategoriasId([])
    setOpenDelete(false)
    setComponentId(null)
    setEditableItem(null)
    setGet(true)
  }

  const handleEdit = (component: Data): void => {
    setEditableItem(component)
    setComponentId(component.id)
    setProduto(component.nome)
    setUnidade(component.unidade !== null ? component.unidade?.id : null)
    setOpenEdit(true)
  }

  const handleProdutoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setProduto(event.target.value)
  }

  const handleSaveChanges = async (): Promise<void> => {
    if (!produto) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o produto para continuar',
        type: 'error'
      }))
      return
    }

    if (produto.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O produto deve conter no máximo 255 caracteres',
        type: 'error'
      }))
      return
    }

    if (!unidade) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a unidade do produto para continuar',
        type: 'error'
      }))
      return
    }

    const body = {
      produto,
      unidadeId: unidade
    }

    if (componentId === null) return

    const response = await backEnd('PUT', `produto/${componentId}`, body)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setShownCategoria('')
    setProduto('')
    setUnidade(null)
    setShownUnidade('')
    setSelectedCategorias([])
    setCategoriasId([])
    setComponentId(null)
    setEditableItem(null)
    setOpenEdit(false)
    setGet(true)
  }

  const handleEditTemp = (component: TempData): void => {
    setEditableTemp(component)
    setComponentId(component.id)
    setOpenEditTemp(true)
  }

  const handleSelectProduto = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const produto = component.find(item => item.id === id)

    if (!produto) return
    setChoosenProduto(produto)
    setShownChoosenProduto(produto.nome)
    setVisible(true)
  }

  const handleSelectTempCategoria = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const categoria = categorias.find(item => item.id === id)
    setTempCategoria(id)
    setCategoria(id)

    if (!categoria) return
    setShownTempCategoria(categoria.categoria)
    setVisible(true)
  }

  const handleSaveEditTemp = async (): Promise<void> => {
    if (!choosenProduto) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o produto correspondente para continuar',
        type: 'error'
      }))
      return
    }

    if (!editableTemp) return

    const response = await backEnd('PUT', `produto-temp/${editableTemp.id}/designar/${choosenProduto.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setShownTempCategoria('')
    setChoosenProduto(null)
    setShownChoosenProduto('')
    setTempCategoria(0)
    setEditableTemp(null)
    setOpenEditTemp(false)
    setGet(true)
  }

  const sortByString = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.nome.localeCompare(b.nome, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByString = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.nome.localeCompare(b.nome, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByGeneric = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      if (a.qtdSelecionado < b.qtdSelecionado) return -1
      if (a.qtdSelecionado > b.qtdSelecionado) return 1
      return 0
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByGeneric = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      if (a.qtdSelecionado < b.qtdSelecionado) return -1
      if (a.qtdSelecionado > b.qtdSelecionado) return 1
      return 0
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByStringTemp = (): void => {
    const sortedArray = [...tempComponent]
    sortedArray.sort(function (a, b) {
      return a.nome.localeCompare(b.nome, 'pt', { sensitivity: 'base' })
    })
    setTempComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByStringTemp = (): void => {
    const revertedArray = [...tempComponent]
    revertedArray.sort(function (a, b) {
      return a.nome.localeCompare(b.nome, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setTempComponent(revertedArray)
    setIsSorted(false)
  }

  const sortByGenericTemp = (): void => {
    const sortedArray = [...tempComponent]
    sortedArray.sort(function (a, b) {
      if (a.categoriaId < b.categoriaId) return -1
      if (a.categoriaId > b.categoriaId) return 1
      return 0
    })
    setTempComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByGenericTemp = (): void => {
    const revertedArray = [...tempComponent]
    revertedArray.sort(function (a, b) {
      if (a.categoriaId < b.categoriaId) return -1
      if (a.categoriaId > b.categoriaId) return 1
      return 0
    })
    revertedArray.reverse()
    setTempComponent(revertedArray)
    setIsSorted(false)
  }

  return (
    <S.Container>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openCreate}
        onClose={handleCloseCreate}
      >
        <DialogContent>
          <S.ColumnContainer className='selectCategoria' width={90}>
            <InputLabel sx={{ color: '#000', mb: 2 }}>Selecione uma categoria</InputLabel>
            <TextField fullWidth required size='small' select id='categorias' name='categorias' value={categoriaChecked} disabled={categorias.length === 0} onChange={handleCategoriaOnCreate} label='Categoria'>
              <MenuItem value={categoriaChecked} style={{ display: 'none' }} disabled>{categoriaChecked}</MenuItem>
              {
                categorias.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.categoria}</MenuItem>
                ))
              }
            </TextField>
          </S.ColumnContainer>
          <S.RowContainer style={{ justifyContent: 'flex-start' }}>
            {
              selectedCategorias.map((elem, index) => (
                <MenuItem key={index} value={elem.categoria.id}>
                  <FormControlLabel control={<Checkbox checked={elem.checked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { dropCategoria(e, elem.categoria.id) }} />} label={elem.categoria.categoria} />
                </MenuItem>
              ))
            }
          </S.RowContainer>
          <Button disabled={!nameProduto} onClick={continueCreating}>Continuar</Button>
          {
            chooseUnit && (
              <>
                <S.ColumnContainer className='selectUnit' width={90}>
                  <InputLabel sx={{ color: '#000', mb: 2 }}>Selecione uma unidade</InputLabel>
                  <TextField fullWidth required size='small' select id='unidades' name='unidades' value={shownUnidade} disabled={unidades.length === 0} onChange={handleSelectUnidade} label='Unidade'>
                    <MenuItem value={shownUnidade} style={{ display: 'none' }} disabled>{shownUnidade}</MenuItem>
                    {
                      unidades.map((item, index) => (
                        <MenuItem key={index} value={item.id}>{item.unidade}</MenuItem>
                      ))
                    }
                  </TextField>
                </S.ColumnContainer>
                <Button disabled={!nameProduto} onClick={continueCreatingFinal}>Continuar</Button>
              </>
            )
          }
          {
            chooseName && (
              <>
                <InputLabel sx={{ color: '#000', mb: 4 }}>Informe o nome do produto:</InputLabel>
                <S.RowContainer>
                  <S.ColumnContainer width={100}>
                    <TextField fullWidth className='input' size='small' id='novoProduto' name='novoProduto' defaultValue={produto} onChange={handleProduto} label='Novo Produto' />

                  </S.ColumnContainer>
                </S.RowContainer>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={!chooseName || !nameProduto} onClick={handleSave}>Salvar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseCreate}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openEdit}
        onClose={handleCloseEdit}
      >
        <DialogContent>
          <S.ColumnContainer width={90}>
            <h3 className='title'>{editableItem !== null ? editableItem.nome : 'Produto'}</h3>
          </S.ColumnContainer>
          {
            editableItem !== null && (
              <>
                <InputLabel sx={{ color: '#000', mb: 4 }}>Editar informações:</InputLabel>
                <S.ColumnContainer width={95}>
                  <S.ColumnContainer width={100}>
                    <TextField fullWidth className='input' size='small' id='novoNome' name='novoNome' defaultValue={editableItem.nome} onChange={handleProdutoChange} label='Produto' />
                    <TextField fullWidth className='input mt' size='small' select id='unidades' name='unidades' defaultValue={editableItem.unidade !== null ? editableItem.unidade.unidade : ''} disabled={unidades.length === 0} onChange={handleSelectUnidade} label='Unidade'>
                      <MenuItem value={editableItem.unidade !== null ? editableItem.unidade.unidade : ''} style={{ display: 'none' }} disabled>{editableItem.unidade !== null ? editableItem.unidade.unidade : ''}</MenuItem>
                      {
                        unidades.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.unidade}</MenuItem>
                        ))
                      }
                    </TextField>
                  </S.ColumnContainer>
                </S.ColumnContainer>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveChanges}>Salvar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEdit}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openDelete}
        onClose={handleCloseDelete}
      >
        <DialogContent>
          <S.ColumnContainer width={90}>
            <h3 className='title'>{editableItem !== null ? editableItem.nome : 'Produto'}</h3>
          </S.ColumnContainer>
          {
            editableItem !== null && (
              <>
                <InputLabel sx={{ color: '#000', mb: 4 }}>Deletar produto?</InputLabel>
                <S.ColumnContainer width={90}>
                  <p>Atenção: Essa ação não poderá ser desfeita. Deseja prosseguir com a exclusão do produto?</p>
                  <S.RowContainer>
                    <Button onClick={() => { setWillDelete(true) }}>Sim, quero deletar</Button>
                    <Button onClick={handleCloseDelete}>Não, manter o produto</Button>
                  </S.RowContainer>
                </S.ColumnContainer>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={!willDelete} onClick={handleSaveDelete}>Deletar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDelete}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openEditTemp}
        onClose={handleCloseEditTemp}
      >
        <DialogContent>
          <S.ColumnContainer width={90}>
            <h3 className='title'>{editableTemp !== null ? editableTemp.nome : 'Produto Temporário'}</h3>
          </S.ColumnContainer>
          {
            editableTemp !== null && (
              <>
                <S.ColumnContainer className='selectCategoria' width={90}>
                  <InputLabel sx={{ color: '#000', mb: 2 }}>Selecione uma categoria</InputLabel>
                  <TextField fullWidth required size='small' select id='categorias' name='categorias' value={shownTempCategoria} disabled={categorias.length === 0} onChange={handleSelectTempCategoria} label='Categoria'>
                    <MenuItem value={shownTempCategoria} style={{ display: 'none' }} disabled>{shownTempCategoria}</MenuItem>
                    {
                      categorias.map((item, index) => (
                        <MenuItem key={index} value={item.id}>{item.categoria}</MenuItem>
                      ))
                    }
                  </TextField>
                </S.ColumnContainer>
                {
                  tempCategoria !== 0 && (
                    <>
                      <InputLabel sx={{ color: '#000', mb: 4 }}>Selecione o produto correspondente a esse produto temporário:</InputLabel>
                      <S.RowContainer>
                        <TextField fullWidth required size='small' select id='produtos' name='produtos' value={shownChoosenProduto} disabled={component.length === 0} onChange={handleSelectProduto} label='Produto'>
                          <MenuItem value={shownChoosenProduto} style={{ display: 'none' }} disabled>{shownChoosenProduto}</MenuItem>
                          {
                            component.map((item, index) => (
                              <MenuItem key={index} value={item.id}>{item.nome}</MenuItem>
                            ))
                          }
                        </TextField>
                      </S.RowContainer>
                    </>
                  )
                }
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEditTemp}>Salvar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEditTemp}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <S.RowContainer>
        {
          categorias.length > 0 && component
            ? (
              <>
                <S.ColumnContainer className='selectCategoria' width={50}>
                  <InputLabel sx={{ color: '#000', mb: 2 }}>Selecione uma categoria</InputLabel>
                  <TextField fullWidth required size='small' select id='categorias' name='categorias' value={shownCategoria} disabled={categorias.length === 0 || showTemp} onChange={handleSelectCategoria} label='Categoria'>
                    <MenuItem value={shownCategoria} style={{ display: 'none' }} disabled>{showTemp ? '' : shownCategoria}</MenuItem>
                    {
                      categorias.map((item, index) => (
                        <MenuItem key={index} value={item.id}>{item.categoria}</MenuItem>
                      ))
                    }
                  </TextField>
                </S.ColumnContainer>
                <S.ColumnContainer className='buttons' width={45}>
                  <Button className='produtos' variant='outlined' onClick={handleOpenCreate}>Cadastrar novo produto</Button>
                  <Button className='produtos' onClick={showTables} variant='outlined'>{showTemp ? 'Ver Produtos' : 'Ver Produtos Temporários'}</Button>
                </S.ColumnContainer>
              </>
            )
            : (
              <S.ColumnContainer width={100}>
                <h4>Não foi possível encontrar categorias cadastradas.</h4>
                <h4>Faça o cadastro de uma categoria para poder cadastrar produtos.</h4>
              </S.ColumnContainer>
            )
        }
      </S.RowContainer>
      {
        loading
          ? (
            <S.LoadingContainer>
              <CircularProgress />
            </S.LoadingContainer>
          )
          : (
            <>
              {
                visible && (
                  <S.ScreenContainer>
                    {
                      component.length > 0 || showTemp
                        ? (
                          <>
                            <S.RowContainer className='top'>
                              {
                                showTemp
                                  ? (
                                    <h4 style={{ color: '#000' }}>Fo{tempCategorias.length !== 1 ? 'ram' : 'i'} encontrado{tempCategorias.length !== 1 ? 's' : ''} {tempCategorias.length} produto{tempCategorias.length !== 1 ? 's' : ''}:</h4>
                                  )
                                  : (
                                    <h4 style={{ color: '#000' }}>Fo{component.length !== 1 ? 'ram' : 'i'} encontrado{component.length !== 1 ? 's' : ''} {component.length} produto{component.length !== 1 ? 's' : ''}:</h4>
                                  )
                              }
                            </S.RowContainer>

                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 700 }} stickyHeader>
                                <TableHead>
                                  {
                                    showTemp
                                      ? (
                                        <TableRow>
                                          <StyledTableCell><Button onClick={() => {
                                            !isSorted
                                              ? sortByStringTemp()
                                              : revertArrayByStringTemp()
                                          }}>Produto</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => {
                                            !isSorted
                                              ? sortByStringTemp()
                                              : revertArrayByStringTemp()
                                          }}>Unidade</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => {
                                            !isSorted && !showTemp
                                              ? sortByGenericTemp()
                                              : revertArrayByGenericTemp()
                                          }}>Categoria</Button></StyledTableCell>
                                          <StyledTableCell></StyledTableCell>
                                        </TableRow>

                                      )
                                      : (
                                        <TableRow>
                                          <StyledTableCell><Button onClick={() => {
                                            !isSorted
                                              ? sortByString()
                                              : revertArrayByString()
                                          }}>Produto</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => {
                                            !isSorted
                                              ? sortByString()
                                              : revertArrayByString()
                                          }}>Unidade</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => {
                                            !isSorted && !showTemp
                                              ? sortByGeneric()
                                              : revertArrayByGeneric()
                                          }}>Vezes incluído</Button></StyledTableCell>
                                          <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                      )
                                  }
                                </TableHead>
                                {
                                  showTemp
                                    ? (
                                      <TableBody>
                                        {
                                          tempComponent.length > 0 && (
                                            <>
                                              {
                                                tempCategorias.map((item, index) => {
                                                  return (
                                                    <StyledTableRow key={index}>
                                                      <StyledTableCell align="left">{item.nome}</StyledTableCell>
                                                      <StyledTableCell align="left">{item.unidade !== null ? item.unidade.unidade : '-'}</StyledTableCell>
                                                      <StyledTableCell align="left">{item.categoria}</StyledTableCell>
                                                      <StyledTableCell align="left">
                                                        <Button onClick={() => { handleEditTemp(item) }}><ModeOutlinedIcon /></Button>
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  )
                                                })
                                              }
                                            </>
                                          )
                                        }
                                      </TableBody>
                                    )
                                    : (
                                      <TableBody>
                                        {
                                          component.map((item, index) => {
                                            return (
                                              <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{item.nome}</StyledTableCell>
                                                <StyledTableCell align="left">{item.unidade !== null ? item.unidade.unidade : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{item.qtdSelecionado}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                  <S.RowContainer>
                                                    <Button onClick={() => { handleDelete(item) }}><DeleteOutlinedIcon /></Button>
                                                    <Button onClick={() => { handleEdit(item) }}><ModeOutlinedIcon /></Button>
                                                  </S.RowContainer>
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            )
                                          })
                                        }
                                      </TableBody>
                                    )
                                }
                              </Table>
                            </TableContainer>
                          </>
                        )
                        : (
                          <h4>Não foi possível encontrar produtos cadastrados</h4>
                        )
                    }
                  </S.ScreenContainer>
                )
              }
            </>
          )
      }
    </S.Container>
  )
}
