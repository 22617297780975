// Arquivo criado: 22/09/2023 às 22:37
import React from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'

interface Data {
  id: number
  active: boolean
  resume?: string
  rules: string
  images: string[]
  title: string
  link: string
}

export const ComprasColetivas = (): JSX.Element => {

  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isSorted, setIsSorted] = React.useState(false)

  const [id, setId] = React.useState<number | null>(null)
  const [active, setActive] = React.useState(false)
  const [titulo, setTitulo] = React.useState('')
  const [link, setLink] = React.useState('')
  const [resume, setResume] = React.useState('')
  const [rules, setRules] = React.useState('')
  const [openCreateCompra, setOpenCreateCompra] = React.useState(false)

  const [file, setFile] = React.useState<File | undefined>(undefined)
  const [loadingModal, setLoadingModal] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Compras Coletivas'

      setLoading(true)
      const response = await backEnd('GET', 'compras-coletivas/admin')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      setComponent(response.data)

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [dispatch, get])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const sortByTitulo = (): void => {
    const sortedArray = [...component]
    sortedArray.sort(function (a, b) {
      return a.title.localeCompare(b.title, 'pt', { sensitivity: 'base' })
    })
    setComponent(sortedArray)
    setIsSorted(true)
  }

  const revertArrayByTitulo = (): void => {
    const revertedArray = [...component]
    revertedArray.sort(function (a, b) {
      return a.title.localeCompare(b.title, 'pt', { sensitivity: 'base' })
    })
    revertedArray.reverse()
    setComponent(revertedArray)
    setIsSorted(false)
  }

  const handleOpenCreateCompra = (): void => {
    setOpenCreateCompra(true)
  }

  const handleCloseCreateCompra = (): void => {
    setOpenCreateCompra(false)
    setTitulo('')
    setLink('')
    setResume('')
    setRules('')
    setId(null)
    setActive(false)
    setFile(undefined)
  }

  const handleTitulo = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitulo(event.target.value)
  }

  const handleCreateCompra = async (): Promise<void> => {

    let base64

    if (!titulo || titulo === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o título da compra para continuar',
        type: 'error'
      }))
      return
    }

    if (!rules || rules === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe as regras da compra para continuar',
        type: 'error'
      }))
      return
    }

    if (!link || link === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o link do grupo para continuar',
        type: 'error'
      }))
      return
    }

    if (!file && !id) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione uma imagem para continuar',
        type: 'error'
      }))

      return
    }

    if (file) {
      base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file as Blob)
        reader.onload = () => { resolve(reader.result) }
        reader.onerror = error => { reject(error) }
      })

      if (!base64) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Ocorreu um erro ao tentar enviar a imagem. Por favor, tente novamente',
          type: 'error'
        }))

        return
      }
    }

    const body = {
      ...file && {
        files: [
          {
            base64,
            size: file.size,
            name: file.name,
            type: file.type
          }
        ]
      },
      grupo: {
        title: titulo,
        active,
        resume: resume || undefined,
        rules,
        link
      }
    }

    setLoadingModal(true)

    const method = id ? 'PUT' : 'POST'
    const url = id ? `compras-coletivas/${id}` : 'compras-coletivas'
    const response = await backEnd(method, url, body)
    setLoadingModal(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: response.msg || 'Efetuado com sucesso!',
      type: 'success'
    }))

    setOpenCreateCompra(false)
    setId(null)
    setTitulo('')
    setLink('')
    setResume('')
    setRules('')
    setFile(undefined)
    setGet(true)
  }

  const uploadImageValidation = (file: File | undefined): void => {
    if (file) {

      const isImage1 = /(\.jpeg)$/i
      const isImage2 = /(\.png)$/i
      const isImage3 = /(\.jpg)$/i

      if (!isImage1.exec(file.name) && !isImage2.exec(file.name) && !isImage3.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .jpg, .jpeg ou .png',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      const fileSize = file.size

      if (fileSize > 2000000) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A imagem selecionada é muito grande. O tamanho máximo é de 2MB',
          type: 'error'
        }))

        return
      }

      setFile(file)
    }
  }

  const handleEditCompra = async (data: Data): Promise<void> => {

    setId(data.id)
    setActive(data.active)
    setTitulo(data.title)
    setLink(data.link)
    setResume(data.resume ?? '')
    setRules(data.rules)
    setOpenCreateCompra(true)

  }

  return (
    <S.Container>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openCreateCompra}
        onClose={handleCloseCreateCompra}
      >
        <DialogTitle id="alert-dialog-title">
          {id ? 'Editar' : 'Criar nova'} Compra
        </DialogTitle>
        <S.DialogContent>
          {
            loadingModal
              ? (
                <S.LoadingContainer>
                  <CircularProgress />
                </S.LoadingContainer>
              )
              : (
                <>
                  <S.RowContainer>
                    <S.ColumnContainer width={99}>
                      <S.RowContainer style={{ justifyContent: 'flex-start', marginBottom: 15 }}>
                        <h5>Ativo?</h5>
                        <Switch
                          checked={active}
                          onChange={() => { setActive(!active) }}
                          style={{ alignSelf: 'center' }}
                        />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField fullWidth className='input' size='small' id='titulo' name='titulo' value={titulo} onChange={handleTitulo} label='Título' />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField type='url' fullWidth className='input' size='small' id='link' name='link' value={link} onChange={(e) => { setLink(e.target.value) }} label='Link' />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField fullWidth className='input' size='small' id='resume' name='resume' value={resume} onChange={(e) => { setResume(e.target.value) }} label='Resumo' />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField multiline minRows={2} fullWidth className='input' size='small' id='rules' name='rules' value={rules} onChange={(e) => { setRules(e.target.value) }} label='Regras' />
                      </S.RowContainer>
                    </S.ColumnContainer>
                  </S.RowContainer>
                  <S.RowContainer>
                    <S.SelectFileBox>
                      <label htmlFor='file'>SELECIONAR {id && 'NOVA'} IMAGEM</label>
                      <input id='file' name='file' type='file' value={''} required accept='image/png, image/jpg, image/jpeg' placeholder='Anexar Arquivo' onChange={(e) => { uploadImageValidation(e.target.files?.[0]) }} />
                    </S.SelectFileBox>
                  </S.RowContainer>
                  {
                    (file) && (
                      <>
                        <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#F1B61744', borderRadius: 8, marginBottom: 10 }}>
                          <p><strong>Arquivo selecionado:</strong> {file.name}</p>
                        </S.RowContainer>
                        <S.RowContainer style={{ justifyContent: 'space-evenly' }}>
                          <Button variant='outlined' onClick={() => { setFile(undefined) }}>Excluir</Button>
                        </S.RowContainer>
                      </>
                    )
                  }
                </>
              )
          }
        </S.DialogContent>
        <DialogActions>
          <Button onClick={handleCreateCompra}>Salvar</Button>
          <Button onClick={handleCloseCreateCompra}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                <S.RowContainer className='top'>
                  {
                    component.length > 0 && (
                      <h4>Fo{component.length !== 1 ? 'ram' : 'i'} encontrada{component.length !== 1 ? 's' : ''} {component.length} compra{component.length !== 1 ? 's' : ''}:</h4>
                    )
                  }
                  <Button variant='outlined' onClick={handleOpenCreateCompra}>{id ? 'Editar' : 'Criar nova'} Compra</Button>
                </S.RowContainer>
                {
                  component.length > 0
                    ? (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell><Button onClick={() => {
                                !isSorted
                                  ? sortByTitulo()
                                  : revertArrayByTitulo()
                              }}>Título</Button></StyledTableCell>
                              <StyledTableCell><Button>Situação</Button></StyledTableCell>
                              <StyledTableCell><Button>Resumo</Button></StyledTableCell>
                              <StyledTableCell><Button>Imagem</Button></StyledTableCell>
                              <StyledTableCell><Button>URL</Button></StyledTableCell>
                              <StyledTableCell><Button>Regras</Button></StyledTableCell>
                              <StyledTableCell><Button>Editar</Button></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              component.map((item, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">{item.title}</StyledTableCell>
                                    <StyledTableCell align="left">{item.active ? 'Ativo' : 'Desativado'}</StyledTableCell>
                                    <StyledTableCell align="left">{item.resume && item.resume.length > 25 ? `${item.resume.substring(0, 25)}...` : item.resume ?? '-'}</StyledTableCell>
                                    <StyledTableCell align="left"><a href={item.images[0]} target='_blank' rel="noreferrer">{item.images[0] ? 'Abrir imagem' : '-'}</a></StyledTableCell>
                                    <StyledTableCell align="left"><a href={item.link} target='_blank' rel="noreferrer">{item.link ? 'Link Grupo' : '-'}</a></StyledTableCell>
                                    <StyledTableCell align="left">{item.rules.length > 25 ? `${item.rules.substring(0, 25)}...` : item.rules}</StyledTableCell>
                                    <StyledTableCell align="center"><Button className='icon' onClick={() => { void handleEditCompra(item) }}><ModeOutlinedIcon /></Button></StyledTableCell>
                                  </StyledTableRow>
                                )
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                    : (
                      <h4>Nenhuma compra coletiva encontrada</h4>
                    )
                }
              </>
            )
        }

      </S.ScreenContainer>
    </S.Container>
  )
}
