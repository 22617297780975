// Arquivo criado: 13/07/2023 às 18:33
import React from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { backEnd } from '../../utils/backend.util'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import CircularProgress from '@mui/material/CircularProgress'

interface Response {
  ok: boolean
  value: string
}

interface Fornecedor {
  nomeFantasia: string
  cnpj: string
  criadoEm: Date
  statusId: number
  nomeResponsavel: string
  email: string
  telefone: string
  id: number
}

interface Indicacao {
  id: number
  usuarioId: number
  valor: number
  finalizado: boolean | null
}

interface Anuncio {
  id: number
  diasRestantes: number
  imagens: string[]
  preco: number
  titulo: string
  status: {
    id: number
    status: string
  }
}

export const Home = (): JSX.Element => {

  const dispatch = useDispatch()

  const [openCreateNotification, setOpenCreateNotification] = React.useState(false)
  const [notificationTitle, setNotificationTitle] = React.useState<string>('')
  const [notificationMessage, setNotificationMessage] = React.useState<string>('')
  const [termos, setTermos] = React.useState<Response | null>(null)
  const [privacidade, setPrivacidade] = React.useState<Response | null>(null)
  const [openChangeAddons, setOpenChangeAddons] = React.useState(false)
  const [fornecedores, setFornecedores] = React.useState<Fornecedor[]>([])
  const [indicacao, setIndicacao] = React.useState<Indicacao[]>([])
  const [anuncio, setAnuncio] = React.useState<Anuncio[]>([])
  const [newAddon, setNewAddon] = React.useState('')
  const [newValue, setNewValue] = React.useState<string | undefined>('')
  const [atualValue, setAtualValue] = React.useState<string | undefined>('')
  const [newTitle, setNewTitle] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [update, setUpdate] = React.useState(false)
  const [get, setGet] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Dashboard'

      const response = await backEnd('GET', 'addon/termos')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }

      setTermos(response as any)

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [dispatch, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Dashboard'

      const response2 = await backEnd('GET', 'addon/privacidade')

      if (!response2.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response2.msg
        }))
      }
      setPrivacidade(response2 as any)

      if (get) {
        setGet(false)
      }

    }
    void getData()
  }, [dispatch, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Dashboard'

      setLoading(true)
      const response3 = await backEnd('GET', 'usuarios/1')
      setLoading(false)

      if (!response3.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response3.msg
        }))
      }
      if (response3.data !== null && response3.data !== undefined) {
        setFornecedores(response3.data)
      }

      setLoading(true)
      const response4 = await backEnd('GET', 'resgate-cashback-lojista')
      setLoading(false)

      if (!response4.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response4.msg
        }))
      }

      if (response4.data !== null && response4.data !== undefined) {
        setIndicacao(response4.data)
      }

      setLoading(true)
      const response5 = await backEnd('GET', 'anuncios/admin')
      setLoading(false)

      if (!response5.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response5.msg
        }))
      }
      if (response5.data !== null && response5.data !== undefined) {
        setAnuncio(response5.data)
      }
      setAnuncio(response5.data)
    }
    void getData()
  }, [dispatch])

  const handleOpenCreateNotification = (): void => {
    setOpenCreateNotification(true)
  }

  const handleCloseCreateNotification = (): void => {
    setNotificationTitle('')
    setNotificationMessage('')
    setOpenCreateNotification(false)

  }

  const handleNotificationTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNotificationTitle(event.target.value)
  }

  const handleNotificationMessage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNotificationMessage(event.target.value)
  }

  const handleCreateNotification = async (): Promise<void> => {
    if (!notificationTitle) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o título da notificação para continuar',
        type: 'error'
      }))
      return
    }

    if (notificationTitle.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O título deve conter no máximo 255 caracteres',
        type: 'error'
      }))
      return
    }

    if (!notificationMessage) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe a mensagem da notificação para continuar',
        type: 'error'
      }))
      return
    }

    if (notificationMessage.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A mensagem deve conter no máximo 255 caracteres',
        type: 'error'
      }))
      return
    }

    const body = {
      titulo: notificationTitle,
      mensagem: notificationMessage
    }

    const response = await backEnd('POST', 'admin/notificacao-global', body)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    setNotificationTitle('')
    setNotificationMessage('')
    setOpenCreateNotification(false)
  }

  const handleCloseChangeAddons = (): void => {
    setNewValue('')
    setNewAddon('')
    setNewTitle('')
    setAtualValue('')
    setUpdate(false)
    setGet(true)
    setOpenChangeAddons(false)
  }

  const handleChangeAddonValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewValue(event.target.value)
    setUpdate(true)
  }

  const handleEditTermos = (): void => {
    setNewAddon('Termos')
    setNewTitle('Termos e Condições')
    setNewValue(termos?.value)
    setAtualValue(termos?.value)
    setOpenChangeAddons(true)
  }

  const handleEditPrivacidade = (): void => {
    setNewAddon('Privacidade')
    setNewTitle('Política de Privacidade')
    setNewValue(privacidade?.value)
    setAtualValue(privacidade?.value)
    setOpenChangeAddons(true)
  }

  const handleUpdateAddon = async (): Promise<void> => {

    if (newValue === atualValue) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Não houve mudanças...'
      }))
      return
    }

    if (newAddon === '' || !newAddon) return

    if (newValue === '' || !newValue) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Digite o novo texto para continuar'
      }))
      return
    }

    const body = {
      value: newValue
    }

    const response = await backEnd('PUT', `addon/${newAddon}`, body)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
    }

    handleCloseChangeAddons()

  }

  return (
    <S.Container>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openCreateNotification}
        onClose={handleCloseCreateNotification}
      >
        <DialogContent>
          <S.ColumnContainer width={90}>
            <InputLabel sx={{ color: '#000', mb: 4 }}>Informe os detalhes da notificação:</InputLabel>
            <S.RowContainer>
              <S.ColumnContainer width={100}>
                <TextField fullWidth className='input' size='small' id='notificationTitle' name='notificationTitle' defaultValue={notificationTitle} onChange={handleNotificationTitle} label='Título da Notificação'/>
                <TextField fullWidth className='input' multiline rows={4} size='small' id='notificationMessage' name='notificationMessage' defaultValue={notificationMessage} onChange={handleNotificationMessage} label='Mensagem da Notificação'/>
              </S.ColumnContainer>
            </S.RowContainer>
          </S.ColumnContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateNotification}>Salvar</Button>
          <Button onClick={handleCloseCreateNotification}>Cancelar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseCreateNotification}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openChangeAddons}
        onClose={handleCloseChangeAddons}
      >
        <DialogContent>
          <>
            <InputLabel sx={{ color: '#000', mb: 4 }}>Escreva o novo texto de {newTitle}:</InputLabel>
            <S.RowContainer>
              <S.ColumnContainer width={90}>
                <TextField fullWidth multiline minRows={4} maxRows={100} className='input' size='small' id='newValue' name='newValue' defaultValue={newValue} onChange={handleChangeAddonValue} label='Novo texto'/>
              </S.ColumnContainer>
            </S.RowContainer>
          </>
        </DialogContent>
        <DialogActions>
          <Button disabled={!update} onClick={handleUpdateAddon}>Salvar</Button>
          <Button disabled={!update} onClick={handleCloseChangeAddons}>Cancelar</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseChangeAddons}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        <S.RowContainer className='main'>
          <S.ColumnContainer className='top'>
            <h2>Bem vindo!</h2>
            {
              loading
                ? (
                  <S.LoadingContainer>
                    <CircularProgress />
                  </S.LoadingContainer>
                )
                : (
                  <S.ColumnContainer className='infos' width={100}>
                    <h4>Fornecedores com cadastro pendente: {fornecedores !== null && fornecedores !== undefined ? (fornecedores.filter((item: Fornecedor) => (item.statusId === 1))).length : '-'}</h4>
                    <h4>Novas solicitações de resgate por indicação: {indicacao !== null && indicacao !== undefined ? (indicacao.filter((item: Indicacao) => (item.finalizado === null || !item.finalizado))).length : '-'}</h4>
                    <h4>Anúncios em análise: {anuncio !== null && anuncio !== undefined ? (anuncio.filter((item: Anuncio) => (item.status.id === 1))).length : '-'}</h4>
                  </S.ColumnContainer>
                )
            }

          </S.ColumnContainer>
          <S.ColumnContainer className='top' width={40}>
            <h3>Push Notification</h3>
            <Button onClick={handleOpenCreateNotification} variant='outlined'>Criar Nova Notificação</Button>
            <h3>Termos e Condições</h3>
            <Button onClick={handleEditTermos} variant='outlined'>Editar Termos de Serviço</Button>
            <h3>Política de Privacidade</h3>
            <Button onClick={handleEditPrivacidade} variant='outlined'>Editar Política de Privacidade</Button>
          </S.ColumnContainer>
        </S.RowContainer>
      </S.ScreenContainer>
    </S.Container>
  )
}
