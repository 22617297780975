import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
  .selectCategoria {
    margin-top: 30px;
    input {
      margin-top: 5px;
    }
  }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .produtos {
    width: 65%;
    margin: 10px 0;
  }
  .css-1qmuxsj-MuiFormLabel-root-MuiInputLabel-root {
    color: ${theme.palette.primary.main};
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  .button {
    margin: 30px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .buttons {
    margin-top: 30px;
    align-items: flex-end;
  }
`
export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h4 {
    color: #000;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .top {
    margin: 15px auto;
    width: 100%;
  }
`

export const LoadingContainer = styled.div`
  align-self: center;
  margin: 130px;
`
