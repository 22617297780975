import axios, { type AxiosError, type ResponseType } from 'axios'
import { store } from '../redux/store'
import { setOpenGlobalMessageAction } from '../redux/actions/globalMessage.action'
import { setLoginUser } from '../redux/actions/user.action'

interface IResponse {
  ok: boolean
  msg: string
  data?: any
}

export const backEnd = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  route: string,
  bodyInfo?: any,
  contentType: 'application/json' | 'multipart/form-data' = 'application/json',
  responseType: ResponseType = 'json'
): Promise<IResponse> => {
  try {

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': contentType
      },
      responseType
    })

    api.interceptors.request.use(
      (config) => {
        const token = store.getState().user.token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      }
    )

    const response = await api.request({
      method,
      url: route,
      data: bodyInfo
    })

    return responseType === 'blob' ? response : response.data
  } catch (error: any) {

    const newError: AxiosError = error

    if (newError.response?.status === 401) {

      const data = newError.response?.data as any

      store.dispatch(setOpenGlobalMessageAction({
        message: data.message,
        type: 'error'
      }))

      if (['jwt expired'].includes(data.message)) {
        store.dispatch(setLoginUser({
          isLogged: false,
          token: null
        }))
      }

    }

    // console.log(newError.response)
    return newError.response?.data as IResponse || {
      ok: false,
      msg: 'Erro ao se comunicar com o servidor'
    }
  }
}
