// Arquivo criado: 28/09/2023 às 16:11
import React from 'react'
import * as S from './styles'
import orcaLogo from '../../assets/logo_horizontal_fundo_branco.png'

export const NaoEncontrado = (): JSX.Element => {

  document.title = 'Não encontrado'

  return (
    <S.Container>
      <S.ScreenContainer>
        <S.LogoContainer>
          <S.LogoImg src={orcaLogo} />
        </S.LogoContainer>
        <S.TextContainer>
          <h2>Oops.. não encontramos nada por aqui.</h2>
        </S.TextContainer>
      </S.ScreenContainer>
    </S.Container>
  )
}
